// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    isDebugMode: true,
    isLocalRun: false,
    awsConfig: {
        region: 'us-east-1',
        APIregion: 'us-east-2',
        IdentityPoolId: 'us-east-1:f6adb3cb-4514-4159-9ffa-f483db4bbe0e',
        UserPoolId: 'us-east-1_4u204xweC',
        UserPoolIdRegion: 'us-east-1',
        ClientId: '7a52seq6f67m3t09ia0qjnta3s',
        cognito_idp_endpoint: '',
        cognito_identity_endpoint: '',
        storageDomain: '.blinkfitness.com'
    },
    apiConf: {
        keyName: 'x-api-key',
        keyValue: 'vwDZKXq3oI6RtruqbINOr9JuUO7Y5Q1h5Wa7EgBD',
        webAPIKEY: '5IiFzVkx7YImrq3xBD5Mb6bxiip8ep1vI4dBOZ00',
        resetPasswordCognito: 'frG5LWjmZk7HNSvcFpew3ahoY6QsnZBJ2MCSfVSk',
        contentType: 'application/json',
        url: 'https://dev.api.sls.blinkfitness.com',
        domain: 'dev.api.sls.blinkfitness.com'
    },
    domain: 'https://dev.web.sls.blinkfitness.com',
    domainSelf: 'https://dev.account.blinkfitness.com',
    cms: {
        cmsApiStage: 'dev',
        cmsStage: 'dev',
        apiUrl: 'https://qa.api.sls.blinkfitness.com/content',
        xApiKey: 'Lu8qwoMCWs4S6A98mIXs25mfanHH0keh8NiPML42'
    },
    planCacheApiConf: {
        keyName: 'x-api-key',
        keyValue: 'WY4jX5fS3C1rc0BvrNQ3u8IvVKLBZN9j6HIBV4wJ',
        contentType: 'application/json',
        apiUrl: 'https://dev.api.sls.blinkfitness.com/pricing-api',
        domain: 'dev.api.sls.blinkfitness.com'
    },
    blinkCorporateEmail: 'arn:aws:sns:us-east-2:923195502435:iblink-corporate-membership-email',
    inTakePopUpSnippetURL: 'https://d311r7cfs9wexv.cloudfront.net/dev/b2-intake-popup.js',
    omnitureSnippetURL: 'https://assets.adobedtm.com/622415e8d5fc8897871fbd8b8d7ac9505137a57b/satelliteLib-abb31248ca3da1fe3dd705c5f0b6bdc793184f60-staging.js',
    zenDeskWidgetURL: 'https://static.zdassets.com/ekr/snippet.js?key=23b5b825-eb2e-4287-a38e-c6feb84b5041'
};


export const AuthConf = {
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: environment.awsConfig.IdentityPoolId,

        // REQUIRED - Amazon Cognito Region
        region: environment.awsConfig.UserPoolIdRegion,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: environment.awsConfig.UserPoolIdRegion,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: environment.awsConfig.UserPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        // userPoolWebClientId: '22getrh34h8a0p6e292mja2hkh',
        userPoolWebClientId: environment.awsConfig.ClientId,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Configuration for cookie storage
        cookieStorage: {
            // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            domain: environment.awsConfig.storageDomain,
            // OPTIONAL - Cookie path
            path: '/',
            // OPTIONAL - Cookie expiration in days
            expires: 365,
            // OPTIONAL - Cookie secure flag
            secure: true
        },
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    API: {
        endpoints: [
            {
                name: 'iBlinkMosoAPI',
                endpoint:  `${environment.apiConf.url}/moso-api`,
                region: 'us-east-2',
                custom_header: async () => {
                    return { 'x-api-key': environment.apiConf.keyValue };
                }
            },
            {
                name: 'iBlinkCognito',
                endpoint: `${environment.apiConf.url}/cognito`,
                region: 'us-east-1',
                custom_header: async () => {
                    return { 'x-api-key': environment.apiConf.keyValue };
                }
            },
            {
                name: 'iBlinkCognitoRequestNewPassword',
                endpoint: `${environment.apiConf.url}/cognito`,
                region: 'us-east-2',
                custom_header: async () => {
                    return { 'x-api-key': 'frG5LWjmZk7HNSvcFpew3ahoY6QsnZBJ2MCSfVSk' };
                }
            },
            {
                name: 'iBlinkPricingAPI',
                endpoint: `${environment.apiConf.url}/pricing-api`,
                region: 'us-east-2',
                custom_header: async () => {
                    return { 'x-api-key': environment.apiConf.keyValue };
                }
            },
            {
                name: 'iBlinkCMSAPI',
                endpoint: `${environment.cms.apiUrl}/content/dev/iblink`,
                region: 'us-east-1',
                custom_header: async () => {
                    return { 'x-api-key': environment.apiConf.keyValue };
                }
            },
            {
                name: 'WebBlinkCMSAPI',
                endpoint: `${environment.apiConf.url}/web-blink.moso-api`,
                region: 'us-east-2',
                custom_header: async () => {
                    return { 'x-api-key': environment.apiConf.webAPIKEY };
                }
            }
        ]
    },
    aws_appsync_region: environment.awsConfig.APIregion,
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: environment.apiConf.keyValue,
};
