import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ToastService} from './helpers/toast.service';
import {LoggerService} from './log4ts/logger.service';
import {messagesOptions} from '../const/message-options';
import {UserService} from './user.service';
import {Observable, BehaviorSubject} from 'rxjs';
import {distinctUntilChanged, retry} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { CreditCardUpdate, CreditCard, MosoUser, User, CognitoUserModel, PayBalanceData } from '../../models';


@Injectable()
export class PaymentService {
    private mosoUser: MosoUser;

    private _creditCards: BehaviorSubject<CreditCard[]> = new BehaviorSubject(null);
    public readonly creditCards: Observable<CreditCard[]> = this._creditCards.asObservable().pipe(distinctUntilChanged());

    /**
     * PaymentService constructor
     * @param {HttpClient} httpClient
     * @param {LoggerService} logger
     * @param {ToastService} toastService
     * @param {UserService} _userService
     */
    constructor(private httpClient: HttpClient, private logger: LoggerService, private toastService: ToastService, private _userService: UserService) {
        this.logger.info('-PaymentService-');
        this.init();
    }

    /**
     * Init Payment service
     **/
    init(): void {
        this._userService.user$.subscribe((user: CognitoUserModel) => {
                if (user && user.mosoId) {
                    this.getCCCardsInfo(user.mosoId);
                }
            }
        );
        this._userService.userDataMoso$.subscribe((data: MosoUser) => {
            if (data && data.RoleId) {
                this.mosoUser = data;
            }
        });
    }

    /**
     * Getting Credit Card Active on member profile
     * */
    getCCCardsInfo(id: string | boolean): void {
        this.httpClient.get<CreditCard[]>(`${environment.apiConf.url}/moso-api/member/${id}/getCardsOnFile`).pipe(retry(1))
            .subscribe(
                (result: CreditCard[]) => this._creditCards.next(result),
                (error: Error | any) => {
                    this.logger.warn(error.error.message);
                    this.toastService.setToastVisible(messagesOptions.requestErrorReload);
                }
            );
    }

    /**
     * getLocalStorage
     * @param key
     * @returns {any | boolean}
     */
    getLocalStorage(key): any | boolean {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : false;
    }

    /**
     * Add funding source and Pay Balance on member profile
     * */
    updateCCInfo(data: CreditCardUpdate): Observable<CreditCardUpdate> {
        // if (!data.PaySourceId) {
        //     delete data['PaySourceId'];
        // }
        if (data) {
            return this.httpClient.post<CreditCardUpdate>(`${environment.apiConf.url}/moso-api/member/${data.mosoId}/fundingsourceNew`, data);
        } else {
            this.logger.warn('No Moso Member ID provided');
        }
    }

    /**
     * Pay Out Standing Balance
     * @param data
     * @param id
     * @returns {Observable<any>}
     */
    payOutStandingBalance(data: PayBalanceData, id: string): Observable<any> {
        if (id !== null && data) {
            return this.httpClient.post<any>(`${environment.apiConf.url}/moso-api/member/${id}/sale`, data);
        } else {
            this.logger.warn('No data provided', data);
        }
    }

    /**
     * Add funding source on member profile
     * Do not use this method
     * */
    payPTOutStandingBalance(data: any, id: any): Observable<any> {
        if (id !== null && data) {
            return this.httpClient.post<any>(`${environment.apiConf.url}/moso-api/member/${id}/purchaseptagreement`, data);
        } else {
            this.logger.warn('No data provided', data);
        }
    }


}


























