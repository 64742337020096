import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CoreModule } from './core/core.module';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { SuccessScreenComponent } from './secure/dashboard/shared/success-screen/success-screen.component';

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        Ng4LoadingSpinnerModule.forRoot()
    ],
    declarations: [
        AppComponent,
        SuccessScreenComponent
    ],
    providers: [{
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
