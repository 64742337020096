import {Injectable, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from './log4ts/logger.service';
import { environment } from '../../../environments/environment';
import * as SettingsData from '../const/constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ToastService } from './helpers/toast.service';
import {AppointmentTimeList, CmsPage, Facility, FitnessGoalList, FooterNavigation, GymLocationsList, HeaderMenueItem, HeaderMobileMenueItem, MembershipPlansInformation, MobileSidebarMenue, MonthsList, OfficialMembershipRule, StatesList, TopHeaderNavigationitem} from '../../models';


@Injectable()
export class SettingsService implements OnInit {

    /** Gym Locations List */
    private _gymLocationsList: BehaviorSubject<Facility[]> = new BehaviorSubject(null);
    public readonly gymLocationsList$: Observable<Facility[]> = this._gymLocationsList.asObservable().pipe(distinctUntilChanged());

    /** Membership Plans Information List */
    private _membershipPlansInformation: BehaviorSubject<MembershipPlansInformation[]> = new BehaviorSubject(null);
    public readonly membershipPlansInformation$: Observable<MembershipPlansInformation[]> = this._membershipPlansInformation.asObservable().pipe(distinctUntilChanged());

    /** States List */
    private _statesList: BehaviorSubject<StatesList[]> = new BehaviorSubject(null);
    public readonly statesList$: Observable<StatesList[]> = this._statesList.asObservable().pipe(distinctUntilChanged());

    /** Months List */
    private _monthsList: BehaviorSubject<MonthsList[]> = new BehaviorSubject(null);
    public readonly monthsList$: Observable<MonthsList[]> = this._monthsList.asObservable().pipe(distinctUntilChanged());


    public facilities: Facility[] = [];
    /**
     * SettingsService constructor
     * @param {HttpClient} httpClient
     * @param {LoggerService} logger
     * @param {ToastService} toastService
     */
    constructor(private httpClient: HttpClient, private logger: LoggerService, private toastService: ToastService) {
        this.logger.info('-CmsService-');
        this.init();
    }

    ngOnInit() {

    }
    /**
     * Getting CMS Settings Data
     * */
    init(): void {
        const url = `${environment.cms.apiUrl}${environment.cms.cmsApiStage ? '/' + environment.cms.cmsApiStage : ''}/iblink/pages?slug=settings`;
        this.httpClient.get<CmsPage[]>(url).pipe(map(settings => settings[0])).subscribe((settings: CmsPage) => {
            this._membershipPlansInformation.next(settings.acf.membership_plans_information);
        });
        this._statesList.next(SettingsData.statesList);
        this._monthsList.next(SettingsData.monthsList);
        this._gymLocationsList.next(JSON.parse(localStorage.getItem('facilities')));

    }

}
