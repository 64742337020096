import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '../../../../core/services/user.service';
import { User } from '../../../../models';

@Component({
    selector: 'ib-success-screen',
    templateUrl: './success-screen.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SuccessScreenComponent implements OnInit, OnDestroy {
    public user: User;
    public subscriptions: Array<Subscription> = [];

    constructor(public _userService: UserService) {
    }

    ngOnInit() {
        this._userService.getCognitoUserData();
        this.subscriptions.push(this._userService.user$.subscribe((data: User) => {
            this.user = data;
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    }

}
