import { Injectable } from '@angular/core';
import { LoggerService } from '../log4ts/logger.service';
import { Agreement, MosoUser } from '../../../models';
import { UserService } from '../user.service';
import { PtService } from '../pt.service';
import { ReferralService } from '../referral.service';


@Injectable()
export class MemberStatesService {
    mosoMember: MosoUser;

    constructor(private logger: LoggerService, private _userService: UserService, private _ptService: PtService, private _referralService: ReferralService) {
        logger.info('-MemberStatesService-');
        this._userService.userDataMoso$.subscribe(result => {
            if (result) {
                this.mosoMember = result;
            }
        });
    }

    isMemberEligibilityToLoginORegister(mosoUser: MosoUser): Promise<boolean> {
        return new Promise((resolve) => {
            let classpass = false;
            if (mosoUser && mosoUser.MembershipAgreements) {
                mosoUser.MembershipAgreements.forEach(item => {
                    classpass = item.AgreementName.toLowerCase().includes('classpass');
                });
                if ((mosoUser.Status === 'Inactive' || mosoUser.Status === 'Hold' || mosoUser.Status === 'Invalid') && Number(mosoUser.Balance) === 0 || classpass) {
                    resolve(false);
                } else {
                    resolve(true);
                }
            }
            resolve(false);
        });
    }

    /**
     * Checking Membership Status Returns Promise of "true/false" if member has no Active membership status
     * @returns {Promise<boolean>}
     */
    noActiveMembership(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.mosoMember) {
                if (!this.mosoMember.MembershipAgreements) {
                    resolve(true);
                }
                this.mosoMember.MembershipAgreements.forEach((ag: any) => {
                    if (ag.MemberAgreementStatus === 'Active') {
                        resolve(false);
                    }
                    resolve(true);
                });
            }
        });
    }


    /**
     * Checking membership to have inactive status
     * @returns {Promise<boolean>}
     */
    inactiveMembershipStatus(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.mosoMember) {
                if (this.mosoMember.Status === 'Inactive') {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }
        });
    }

    /**
     * Checking member has or doesnt have balance
     * @returns {Promise<boolean>}
     */
    memberHasBalance(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.mosoMember) {
                if (this.mosoMember.Balance > 0) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }
        });
    }

    /**
     * agreementHasFutureCancelDate. Return Promise of "true/false" if no Cancellation Date and Active membership status
     * @returns {Promise<boolean>}
     */
    agreementHasFutureCancelDate(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.mosoMember) {
                if (!this.mosoMember.MembershipAgreements) {
                    resolve(true);
                }
                this.mosoMember.MembershipAgreements.forEach((ag: any) => {
                    if (ag.MemberAgreementStatus === 'Active' && !ag.CancellationDate) {
                        resolve(false);
                    }
                    resolve(true);
                });
            }
        });
    }


    /**
     * paidInFullAndComplimentaryMembers return Promise of "true/false" if no Paid in full
     * @returns {Promise<boolean>}
     */
    paidInFullAndComplimentaryMembers(): Promise<boolean> {
        return new Promise((resolve) => {
            this._ptService.agreementsListByMemberId$.subscribe((res: Agreement[]) => {
                if (res) {
                    res.forEach((e: Agreement | any) => {
                        if (e.PurchaseTerms && e.PurchaseTerms.Name) {
                            if (e.PurchaseTerms.Name.includes('Paid In Full')) {
                                resolve(true);
                            }
                        }
                        resolve(false);
                    });
                }
            });
        });
    }


    /**
     * employeesOfBlinkOrEquinox. Return Promise of "true/false" if member has 'blinkfitness.com' or 'equinox.com' email
     * @returns {Promise<boolean>}
     */
    employeesOfBlinkOrEquinox(): Promise<boolean> {
        return new Promise((resolve) => {
            const emailAddresses = this.mosoMember.EmailAddresses.map(e => e.EmailAddress);
            const result = emailAddresses.some((v) => v.indexOf('blinkfitness') >= 0) || emailAddresses.some((v) => v.indexOf('equinox') >= 0) || emailAddresses.some((v) => v.indexOf('equinoxfitness') >= 0);
            resolve(result);
        });
    }

    /**
     * employeesOfBlinkOrEquinox. Return Promise of "true/false" if member has 'blinkfitness.com' or 'equinox.com' email
     * @returns {Promise<boolean>}
     */
    checkEmailEmployeesOfBlinkOrEquinox(email): Promise<boolean> {
        return new Promise((resolve) => {
            const result = email.indexOf('blinkfitness') >= 0 || email.indexOf('equinox') >= 0 || email.indexOf('equinoxfitness') >= 0;
            resolve(result);
        });
    }

    /**
     * haveAlreadyMade25Referrals. Return Promise of "true/false" if member reached limit of Creation referrals
     * @returns {Promise<boolean>}
     */
    haveAlreadyMade25Referrals(): Promise<boolean> {
        return new Promise(resolve => {
            this._referralService.getReferralList().toPromise().then((e: any) => {
                resolve(e.length >= 25);
            });
        });
    }


}
