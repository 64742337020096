import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment, AuthConf } from './environments/environment';
import Amplify, {API, AWSPinpointProvider, Auth} from 'aws-amplify';
Amplify.configure(AuthConf);


if (environment.production) {
    enableProdMode();
} else {
}


platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
