import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { LoggerService } from '../log4ts/logger.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { from as observableFromPromise, Observable } from 'rxjs';
import { mergeMap, finalize, tap } from 'rxjs/operators';
import { AwsService } from './aws.service';
import { environment } from '../../../../environments/environment';
import 'rxjs/add/operator/catch';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
    contador = 0;

    constructor(private spinnerService: Ng4LoadingSpinnerService, private _awsService: AwsService, public logger: LoggerService) {
        this.logger.info('-- HttpsRequestInterceptor --');
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.contador++;
        const parts = req.url.split('/');
        const lastSegment = parts.pop() || parts.pop();

        if (this.contador === 1 && lastSegment !== 'getCardsOnFile') {
            this.spinnerService.show();
        }

        return observableFromPromise(
            this._awsService.getAuthorizationHeaders(req).catch(err => {
                this.spinnerService.hide();
            })).pipe(mergeMap(
            (v4headers: any) => {
                if (v4headers && (req.url.includes('/content') ||
                    req.url.includes('/cms/') ||
                    req.url.includes('/dev/pages') ||
                    req.url.includes('/dev/locations') ||
                    req.url.includes('/dev/terms-and-conditions'))
                ) {
                    delete v4headers['X-Amz-User-Agent'];
                    v4headers['x-api-key'] = environment.cms.xApiKey;
                }

                if (v4headers && (req.url.includes('/pricing-api'))) {
                    delete v4headers['X-Amz-User-Agent'];
                    v4headers['x-api-key'] = environment.planCacheApiConf.keyValue;
                }

                const _req = req.clone({
                    url: req.url,
                    setHeaders: v4headers
                });
                return next.handle(_req)
                    .pipe(
                        tap(),
                        finalize(() => {
                            this.contador--;
                            if (this.contador === 0) {
                                this.spinnerService.hide();
                            }
                        })
                    );
            }));
    }

}





