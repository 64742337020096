import { FormControl, FormGroup } from '@angular/forms';
import * as AppConstants from './const/constants';
import * as _ from 'lodash';
import { Month, MosoUser } from '../models';
import { Subscription } from 'rxjs';




export function calculateTax(price: string, taxRite: number): string {
    const tax = Number(price) * taxRite;
    return tax.toFixed(2);
}

export function grandTotal(price, tax): string {
    const total = Number(price) + Number(tax);
    return total.toFixed(2);
}

/**
 * Clean All Subscriptions. Call function from ngOnDestroy()
 * cleanSubscriptions(): void
 */
export function cleanSubscriptions(subscriptions: Subscription[]): void {
    subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
}

/**
 * Validation all form controls
 * @param {FormGroup} formGroup
 */
export function validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
            control.markAsDirty({ onlySelf: true });
        } else if (control instanceof FormGroup) {
            validateAllFormFields(control);
        }
    });
}

/**
 * unValidate All Form controls
 * @param {FormGroup} formGroup
 */
export function unValidateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsUntouched({onlySelf: true});
        } else if (control instanceof FormGroup) {
            unValidateAllFormFields(control);
        }
    });
}


export function resetInputs(formGroup: FormGroup): void {
    formGroup.reset();
}

/**
 * Get Current Year
 * @returns {number}
 */
export function getCurrentYear(): number {
    const d = new Date();
    return d.getFullYear();
}

/**
 * Get Years limit (currently = 51)
 * @returns {any[]}
 */
export function getYearsLimit(): any[] {
    const yearsList = [];
    const d = new Date();
    const cy = d.getFullYear();
    for (let i = 0; i < 51; i++) {
        yearsList.push(cy + i);
    }
    return yearsList;
}

/**
 * getCardType(number): string
 * checking credit card numbers and returning creadit card name
 * @param number
 * @returns {string}
 */
export function getCardType(number: string): string {
    // visa
    let re = new RegExp('^4');
    if (number.match(re) !== null) {
        return 'Visa';
    }
    // Mastercard
    if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) {
        return 'Mastercard';
    }
    // AMEX
    re = new RegExp('^3[47]');
    if (number.match(re) !== null) {
        return 'AmericanExpress';
    }
    // Discover
    re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
    if (number.match(re) !== null) {
        return 'Discover';
    }
    return '';
}

/**
 *  filterMonth that can be selected year to year
 * @param selectedYear
 */
export function filterMonth(selectedYear: string): Month[] {
    if (selectedYear === getCurrentYear().toString()) {
        const d = new Date();
        const currentMonth = d.getMonth() + 1;
        return _.filter(AppConstants.monthsList, (o: any) => {
            return o.number >= currentMonth;
        });
    } else {
       return AppConstants.monthsList;
    }
}

/**
 * formatMonth(): string
 * format month 2 -> 02
 * */
export function formatMonth(m: number): string | number {
    return m < 10 ? '0' + m : m;
}

/**
 * formatYear(yyyy: any): string
 * format year 2018 -> 18
 * */
export function formatYear(yyyy: string): string {
    return ('' + yyyy).substring(2);
}
