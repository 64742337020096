import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {LoggerService} from '../log4ts/logger.service';

@Injectable()
export class LoadingScripts {

    constructor(private logger: LoggerService) {
        this.logger.info('-- LoadingScripts --');
    }

    public loadScript(): void {
        const dynamicScript = environment.inTakePopUpSnippetURL;
        const node = document.createElement('script');
        node.src = dynamicScript;
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    public loadScriptSupport(): void {
        const dynamicScript = environment.zenDeskWidgetURL;
        const node = document.createElement('script');
        node.src = dynamicScript;
        node.id = 'ze-snippet';
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    public deleteScript(id): void {
        if (document.getElementById(id)) {
            document.getElementById(id).remove();
        }
    }

    public loadScriptOmniture(): void {
        const dynamicScript = environment.omnitureSnippetURL;
        // const node = document.createElement('script');
        // node.src = dynamicScript;
        // node.id = 'omniture-sn';
        // node.type = 'text/javascript';
        // node.async = false;
        // node.charset = 'utf-8';
        // document.getElementsByTagName('head')[0].appendChild(node);
        document.write(`<script src="${dynamicScript}"></script>`);
    }

}
