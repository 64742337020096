
export const messagesOptions = {
    thanksOK: {
        visible: true,
        options: {
            modalTitle: 'Thanks!',
            modalMessage: '',
            modalButtonTitle: 'OK',
            modalClass: 'thanks-info-pop-up',
            modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right'
        }
    },
    thanksBFFSuccess: {
        visible: true,
        options: {
            modalTitle: 'Thanks!',
            modalMessage: '',
            modalButtonTitle: 'see my bff list',
            modalClass: 'thanks-info-pop-up',
            modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right'
        }
    },
    creditCardUpdatedSuccess: {
        visible: true,
        options: {
            modalTitle: 'Success',
            modalMessage: 'Your credit card was processed successfully',
            modalButtonTitle: 'got it',
            modalClass: 'thanks-info-pop-up',
            modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right'
        }
    },
    passwordUpdateSuccess: {
        visible: true,
        options: {
            modalTitle: 'Success',
            modalMessage: 'Success! Password changed!',
            modalButtonTitle: 'got it',
            modalClass: 'thanks-info-pop-up',
            modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right'
        }
    },
    ptRequest: {
        visible: true,
        options: {
            modalTitle: 'Thanks!',
            modalMessage: 'We\'ll be in touch soon',
            modalButtonTitle: 'OK',
            modalClass: 'pt-request-pop-up',
            modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right'
        }
    },
    referralsIneligibleParticipant: {
        visible: true,
        options: {
            modalTitle: '',
            modalMessage: `We\'re sorry, but this email address is associated <br> with an ineligible participant`,
            modalButtonTitle: 'got it',
            modalClass: 'pt-request-pop-up',
            modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right'
        }
    },
    requestError: {
        visible: true,
        options: {
            modalTitle: '',
            modalMessage: 'Looks like there was an error. Take a deep breath and try again?',
            modalButtonTitle: 'OK',
            modalClass: 'error-pop-up',
            modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right'
        }
    },
    requestErrorReload: {
        visible: true,
        options: {
            modalTitle: '',
            modalMessage: 'Looks like there was an error. Take a deep breath and reload the page?',
            modalButtonTitle: 'OK',
            modalClass: 'error-pop-up',
            modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right'
        }
    },
    requestErrorBillingTimeout: {
        visible: true,
        options: {
            modalTitle: 'hmm',
            modalMessage: 'This is taking longer than expected. Please check back later to see if your account status was updated, or ask a Mood Lifter™ the next time you’re at a Blink. Additionally, you can always call 800-256-1953 with billing questions.',
            modalButtonTitle: 'OK',
            modalClass: 'error-pop-up',
            modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right'
        }
    },
    requestErrorPasswordUpdate: {
        visible: true,
        options: {
            modalTitle: '',
            modalMessage: 'Looks like there was an error. Take a deep breath and reload the page?',
            modalButtonTitle: 'OK',
            modalClass: 'error-pop-up',
            modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right'
        }
    },
    ptPurchaseError: {
        visible: true,
        options: {
            modalTitle: 'Hold on! Something needs your attention.',
            modalMessage: ` <div class="d-flex justify-content-center">
                                <div class="col-10">
                                    <p class="text-center text-navy nimbussans_regular pt-3">Here’s why you can't buy sessions right now:</p>
                                    <ul class="conditions mb-4">
                                        <li>The Blink you joined hasn’t opened yet</li>
                                        <li>You canceled your membership</li>
                                        <li>You have an outstanding balance</li>
                                        <li>You have a Junior Gray membership because you’re under 18</li>
                                        <li>You already have a Monthly PT Plan</li>
                                    </ul>
                                    <p class="text-left p-line-height">Don't stress! A Mood Lifter™ at any Blink gym can help you resolve the issue.</p>
                                </div>
                            </div>`,
            modalButtonTitle: 'Got It!',
            modalClass: 'payment-pop-up',
            modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right btn-arrow'
        }
    },
    ptPurchaseSessionError: {
        visible: true,
        options: {
            modalTitle: 'Hold on! Something needs your attention.',
            modalMessage: ` <div class="d-flex justify-content-center">
                                <div class="col-10">
                                    <p class="text-navy nimbussans_regular pt-3">Here's why you can't buy sessions right now:</p>
                                    <ul class="conditions mb-4">
                                        <li>The Blink you joined hasn’t opened yet</li>
                                        <li>You canceled your membership</li>
                                        <li>You have an outstanding balance</li>
                                        <li>You have a Junior Gray membership because you’re under 18</li>
                                    </ul>
                                    <p class="text-left p-line-height">Don't stress! A Mood Lifter™ at any Blink gym can help you resolve the issue.</p>
                                </div>
                            </div>`,
            modalButtonTitle: 'Got It!',
            modalClass: 'payment-pop-up',
            modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right btn-arrow'
        }
    },
    ptErrorMessage: {
        visible: true,
        options: {
            modalMessage: `
                    <div class="error-message-wrapper">
                    <p class="error-message-popup">There was an error finding your information.<br>
                    Please try again later or speak to a Mood Lifter<sup>TM</sup><br>
                    the next time you're at Blink.</p></div>`,
            modalClass: 'payment-pop-up pay-required-pop',
            isHideButton: true
        }
    },
    popupNoPlan: (RemainingSession: string, RemainingSingleSessions: string) => {
        return {
            visible: true,
            options: {
                modalTitle: 'Personal Training Summary',
                modalMessage: `
                        <hr>
                        <h6 class="tal text-dark-gray">Monthly Plan:</h6>
                        <p class="tal">You don't have a monthly training plan yet!<br>
                        Sign up for one <a class="link text-blue text-underline" href="/iblink/startup" target="_self">here</a></p>
                        <hr>
                        <h6 class="tal text-dark-gray">Single Sessions:</h6>
                        <h6 class="tal">Remaining Sessions: ${RemainingSingleSessions || 0}</h6>
                        <p class="tal">Your number of remaining sessions doesn't include any that have already been scheduled.</p>
                `,
                modalButtonTitle: 'Got it',
                modalClass: 'payment-pop-up pay-required-pop',
                modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right',
                isHideButton: true
            }
        };
    },

    ptPopup: (AgreementName: string, RemainingSession: string, date: any, Results: any, RemainingSingleSessions: string, Qty: any) => {
        let ext;
        switch (date) {
            case 1:
            case 21:
            case 31:
            case '1':
            case '21':
            case '31':
                ext = 'st';
                break;
            case 3:
            case 23:
            case '3':
            case '23':
                ext = 'rd';
                break;
            case 2:
            case 22:
            case '2':
            case '22':
                ext = 'nd';
                break;
            default:
                ext = 'th';
        }
        const plan = AgreementName ? AgreementName.replace('PT', `${Qty} x `).replace('Min Recurring', `minute session`) : '';
        const postfix = Qty > 2 ? 'Plan' : 'Check-in';
        return {
            visible: true,
            options: {
                modalTitle: 'Personal Training Summary',
                modalMessage: `
                    <hr>
                    <h6 class="tal  text-dark-gray">Monthly Plan:</h6>
                    <br>
                    <h6 class="tal">Remaining Sessions: ${RemainingSession}</h6>
                    <p class="tal">Your number of remaining sessions doesn't include any that have already been scheduled.</p>
                    <br>
                    <h6 class="tal">Monthly Plan:</h6>
                    <p class="tal mb-0">Personal Training Monthly ${postfix}</p>
                    <p class="tal">${plan}</p>
                    <br>
                    <h6 class="tal">Plan Billing Date:</h6>
                    <p class="tal">You'll be billed monthly, on the ${date}${ext}</p>
                    <hr>
                    <h6 class="tal  text-dark-gray">Single Sessions:</h6>
                    <h6 class="tal">Remaining Sessions: ${RemainingSingleSessions}</h6>
                    <p class="tal">Your number of remaining sessions doesn't include any that have already been scheduled.</p>
                    `,
                modalButtonTitle: 'Got it',
                modalClass: 'payment-pop-up pay-required-pop',
                modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right',
                isHideButton: true
            }
        };
    },
    rrInformation: {
        visible: true,
        options: {
            modalTitle: 'Being rewarded for your referrals is as simple as:',
            modalMessage: `<div class="px-5 text-justify modal-text-content">
                                <p><strong class="nimbussans_bold text-navy">Invite your friends!</strong> Once you’ve invited them they will show up in your BFF (Blink Fitness Friend) List with the status “INVITED!”</p>
                                <p class="mt-3"><strong class="nimbussans_bold text-navy">Your BFF joins Blink!</strong> As soon as your BFF becomes a
                                member their status will change to “JOINED!”. Once they’ve been a member for 60 days your reward will be applied. Check your BFF List on iBlink to see how many days remain until you receive your rewards.</p>
                                <p class="mt-3 mb-5"><strong class="nimbussans_bold text-navy">You receive your reward!</strong> After your BFF has been a member for 60 days, $10 will be applied to your account and their status will change to “REWARDED!”.</p>
                          </div>`,
            modalButtonTitle: 'Got It!',
            modalClass: 'payment-pop-up',
            modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right btn-arrow',
            isHideButton: true
        }
    },
    rrInformationWhyNot: {
        visible: true,
        options: {
            modalTitle: 'Why can\'t I continue?',
            modalMessage: `<div class="pl-3 pr-3 pb-0 text-justify modal-text-content">
                                <p>Your BFF List might contain the maximum number of referred friends.</p>
                                <p class="mt-3">You might be a member of a club that hasn't opened yet.</p>
                                <p class="mt-3">You might have a complementary or non month-to-month membership.</p>
                                <p class="mt-3 mb-5">You might be a Blink Fitness employee.</p></div>`,
            modalButtonTitle: 'Got It!',
            modalClass: 'payment-pop-up',
            modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right btn-arrow',
            isHideButton: true
        }
    },
    depletedPopup: (AgreementName: string, date: any, Qty: any) => {
        let ext;
        switch (date) {
            case 1:
            case 21:
            case 31:
            ext = 'st';
            break;
            case 3:
            case 23:
            ext = 'rd';
            break;
            case 2:
            case 22:
            ext = 'nd';
            break;
            default:
            ext = 'th';
        }
        return {
            visible: true,
            options: {
                modalTitle: 'Personal Training Summary',
                modalMessage: `
                    <hr>
                    <h6 class="tal  text-dark-gray">Monthly Plan:</h6>
                    <br>
                    <h6 class="tal">Remaining Sessions: 0</h6>
                    <p class="tal">Unused plan sessions roll over to the next month. The number above doesn't include any sessions already scheduled.</p>
                    <br>
                    <h6 class="tal">Monthly Plan:</h6>
                    <p class="tal">${AgreementName}</p>
                    <br>
                    <h6 class="tal">Plan Billing Date:</h6>
                    <p class="tal">You'll be billed monthly, on the ${date}${ext}</p>
                    `,
                modalButtonTitle: 'Got it',
                modalClass: 'payment-pop-up pay-required-pop',
                modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right',
                isHideButton: true
            }
        };
    },
};


