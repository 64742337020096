import {AfterViewChecked, AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {constants} from '../../const/constants';
import {environment} from '../../../../environments/environment';

declare let _satellite: any;

@Component({
    selector: 'ib-left-hidden-side-bar',
    templateUrl: './left-hidden-side-bar.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LeftHiddenSideBarComponent implements OnInit {
    public menusHidden: any;
    public footerMenus: any;
    date: Date = new Date();
    mainDomain = environment.domain;

    ngOnInit() {
        this.menusHidden = constants.menusHidden;
        this.footerMenus = constants.footerMenus;
    }

}
