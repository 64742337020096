import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoggerService } from '../services/log4ts/logger.service';
import { AnalyticsService } from '../services/analytics.service';
import { AwsAmplifyService } from '../services/core/aws-amplify.service';
import { tap } from 'rxjs/operators';
import {LoadingScripts} from '../services/helpers/loading-scripts';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private router: Router, private logger: LoggerService, private analyticsService: AnalyticsService, private _awsAmplifyService: AwsAmplifyService, private loadingScript: LoadingScripts) {
        this.logger.info('-AuthGuard-');
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean | Promise<boolean> {
        return this._awsAmplifyService.isAuthenticated()
            .pipe(
                tap(loggedIn => {
                    if (!loggedIn) {
                        this.analyticsService.addAccount(null, 'out');
                        this.router.navigate(['public/login']);
                        this.logger.info('Logged out');
                        this.loadingScript.deleteScript('ze-snippet');
                    }
                })
            );
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }
}
