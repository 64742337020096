import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AwsService } from './core/aws.service';
import { LoggerService } from './log4ts/logger.service';
import { AnalyticsService } from './analytics.service';
import { ToastService } from './helpers/toast.service';
import { environment } from '../../../environments/environment';
import { messagesOptions } from '../const/message-options';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, retry, tap } from 'rxjs/operators';
import { LocalStorageService } from './helpers/local-storage.service';
import { User, MosoUser, CognitoUserModel, CmsPage } from '../../models';
import { CookieService } from 'ngx-cookie-service';
import { ScriptService } from './helpers/script.service';
import { AwsAmplifyService } from './core/aws-amplify.service';

declare let $: any;


@Injectable()
export class UserService {
    cookieValue = null;
    private localCognitoUser: CognitoUserModel;
    private localMosoUser: MosoUser;

    public _user: BehaviorSubject<CognitoUserModel | null> = new BehaviorSubject(null);
    public readonly user$: Observable<CognitoUserModel | null> = this._user.asObservable().pipe(distinctUntilChanged());

    public _userDataMoso: BehaviorSubject<MosoUser | null> = new BehaviorSubject(null);
    public readonly userDataMoso$: Observable<MosoUser | null> = this._userDataMoso.asObservable().pipe(distinctUntilChanged());

    private _leftSidebarVisible: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public readonly leftSidebarVisible: Observable<boolean> = this._leftSidebarVisible.asObservable();



    constructor(private _awsService: AwsService,
                private logger: LoggerService,
                private script: ScriptService,
                private toastService: ToastService,
                private httpClient: HttpClient,
                private analyticsService: AnalyticsService,
                private cookieService: CookieService,
                private _amplifyService: AwsAmplifyService) {
        logger.info('-UserService-');
        this._awsService.checkIdentityCredentials();
        this.checkRedirectLocationCookies();
    }

    /////////////////////////////////////AMPLIFY CALLS//////////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////////////

    forgotUserNameP(id: string): Promise<any> {
        const myInit = {
            headers: {
                'x-api-key': environment.apiConf.webAPIKEY
            }
        };
        if (!id) {
            this.logger.warn('ID is missing');
            return;
        }
        // return this._amplifyService.postAmplifyWebBlinkCMSAPI(`/forgotEmail`, id);
        return this.httpClient.post<any>(`${environment.apiConf.url}/web-blink.moso-api/forgotEmail`, id, myInit).toPromise();
    }


    ///////////////////////////////////////////////////////////////////////////////////


    /**
     * static getDifferenceBetweenTwoDates(date1: Date, date2: Date): number
     * @param {Date} date1
     * @param {Date} date2
     * @returns {number}
     */
    public static getDifferenceBetweenTwoDates(date1: Date, date2: Date): number {
        const timeDiff = date2.getTime() - date1.getTime();
        return Math.ceil(timeDiff / (1000 * 3600 * 24));
    }

    /**
     * Checking Cookies to get redirect location "Registration Bridge" and trigger PopUp
     * */
    checkRedirectLocationCookies(): void {
        this.script.load('popUpScript').then(data => {
            // this.logger.info('"$(\'b2-popup\')" -- element is defined', $('b2-popup').length);
            this.cookieValue = this.cookieService.get('locationBridge');
            if (this.cookieValue) {
                $('b2-popup').trigger('showModal');
                this.logger.info('$(\'b2-popup\').trigger(\'showModal\'); -- has been triggered');
                this.cookieService.delete('locationBridge', '/', '.blinkfitness.com');
                this.cookieValue = null;
            }
        }).catch(error => this.logger.error(error));
    }


    isMemberFirstTimeLogIn(): void {
        if (this.localMosoUser.MembershipAgreements) {
            this._awsService.getDataSetData('WelcomePopUp').then(() => {

            }).catch(() => {
                this.analyticsService.setProp('joinRegistration', { type: 'joinFlow' });
                this.analyticsService.satelliteAction('regConfirmed');
                this._awsService.setDataSetData('WelcomePopUp', '1')
                    .then(result => {})
                    .catch(err => this.logger.error(err));
            });
        }
    }


    /**
     * UserService. Check user auth state
     * */
    isUserLoggedIn(): Promise<boolean> {
        return this._awsService.isLoggedIn();
    }

    /**
     * UserService. Get Cognito User Data /getCognitoUserData/, Get MOSO User Data by memberId
     * */
    getCognitoUserData(): void {
        if (this.isUserLoggedIn()) {
            this._awsService.getCognitoUserAttributes()
                .then((cognitoUser: User) => {
                    const cUser: CognitoUserModel = {
                        givenName: cognitoUser['given_name'],
                        familyName: cognitoUser['family_name'],
                        phone: cognitoUser['custom:phone_number'],
                        email: cognitoUser['email'],
                        barcode: cognitoUser['custom:barcode'],
                        mosoId: cognitoUser['custom:moso_member_id'],
                        securityId: cognitoUser['custom:userSecurityId']
                    };
                    this.analyticsService.addAccount(cUser.mosoId, 'in');
                    this._user.next(cUser);
                    this.localCognitoUser = cUser;
                    this.getUserDataMOSO(cUser.mosoId);
                    LocalStorageService.setLocaStorage('userId', cUser.mosoId);
                })
                .catch((err: Error) => this.logger.info(err));
        }
    }


    // getCognitoUserData(): void {
    //     if (this.isUserLoggedIn()) {
    //         this._awsAmplifyService.getCognitoUserData().subscribe(cUser => {+
    6
    //             if (cUser) {
    //                 this.analyticsService.addAccount(cUser.mosoId, 'in');
    //                 this._user.next(cUser);
    //                 this.localCognitoUser = cUser;
    //                 this.getUserDataMOSO(cUser.mosoId);
    //                 LocalStorageService.setLocaStorage('userId', cUser.mosoId);
    //             }
    //         });
    //     }
    // }


    /**
     * UserService. Get MOSO User by memberId
     * */
    getUserDataMOSO(memberId: string): void {
        const myInit = {
            headers: {
                'x-api-key': 'vwDZKXq3oI6RtruqbINOr9JuUO7Y5Q1h5Wa7EgBD'
            }
        };
        if (memberId !== null) {
            this.httpClient.get<MosoUser>(`${environment.apiConf.url}/moso-api/member/${memberId}`, myInit)
                .pipe(retry(1))
                .subscribe((mosoUserData: MosoUser) => {
                        if (mosoUserData) {
                            this.localMosoUser = mosoUserData;
                            this._userDataMoso.next(mosoUserData);
                            this.isMemberFirstTimeLogIn();
                        }
                    },
                    error => {
                        this.logger.error('Can not get MOSO member data: ', error);
                        this.toastService.setToastVisible(messagesOptions.requestError);
                    }
                );
        } else {
            this.logger.info('Member ID is missing');
        }
    }

    /**
     * Update user information data put request
     * @param {MosoUser} userData
     * @returns {Observable<MosoUser>}
     */
    updateUserInformation(userData: MosoUser): Observable<MosoUser> {
        return this.httpClient.put<MosoUser>(`${environment.apiConf.url}/moso-api/member/${userData.RoleId}`, userData).pipe(retry(1));
    }

    /**
     * request Pt Session
     * requestPtSession(userData: any): Observable<any>
     * */
    requestPtSession(data: any): Observable<any> {
        if (data) {
            return this.httpClient.post<any>(`${environment.apiConf.url}/moso-api/member/${data.mosoId}/pt_request`, data).pipe(retry(1));
        }
    }

    /**
     * isFirstDate(): boolean
     * checking first data of the month
     * @returns {boolean}
     */
    isFirstDate(): boolean {
        const d = new Date();
        const date = d.getDate();
        return Number(date) === 1;
    }

    /**
     * setLeftSidebarVisible(value: boolean): void
     * Setting left side bar visible
     * @param {boolean} value
     */
    setLeftSidebarVisible(value: boolean): void {
        this._leftSidebarVisible.next(value);
    }


    /**
     * UserService. Sign Out From Aws Cognito
     * */
    signOutCognito(): void {
        this._awsService.signOut();
    }

    processCorporateEmail(contactInfo): Promise<any> {
        return this._sendCorporateEmail(contactInfo);
        // return this.httpClient.get<MosoUser>(environment.apiConf.url + '/moso-api/member/' + memberId).toPromise();
        //return Observable.of(true).delay(2000).toPromise();
    }

    _sendCorporateEmail(contactInfo) {
        return this._awsService.setUnauthIdentityCredentials()
            .then(() => {
                return this._awsService.idenityPermissions = this._awsService.getIdenityCredentials();
            })
            .then((credentials) => {
                if (credentials.identityId) {
                    let message = {
                        'email': contactInfo.email,
                        'first_name': contactInfo.first_name,
                        'lastname': contactInfo.last_name,
                        'phone': contactInfo.phone,
                        'company_name': contactInfo.company_name,
                        'numbers_employeers': contactInfo.number_of_employees,
                        'area_interest': contactInfo.area_of_interest
                    };

                    return this._awsService.sendContactUsEmail(message, environment.blinkCorporateEmail);
                }
            });
    }


    /**
     *  changePassword(oldPassword: string, newPassword: string)
     * @param {string} oldPassword
     * @param {string} newPassword
     * @returns {Promise<any>}
     */
    changePassword(oldPassword: string, newPassword: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._awsService.updataPasswordNewStack(oldPassword, newPassword).then(res => {
                const data = {
                    password: newPassword,
                    userSecId: this.localCognitoUser.securityId,
                    moso_member_id: this.localCognitoUser.mosoId
                };

                this.changePasswordOldStack(data)
                    .then(result => resolve(result))
                    .catch(error => {
                        this.changePasswordNewStackBack(oldPassword, newPassword)
                            .then(re => this.logger.info(re))
                            .catch(er => {
                                reject(er);
                                return;
                            });
                        reject(error);
                    });

            }).catch(error => {
                this.logger.info('Cannot Update password in Cognito', error);
                reject(error);
            });
        });
    }

    /**
     * Re Roll changePasswordNewStackBack
     * @param oldPassword
     * @param newPassword
     * @returns {Promise<any>}
     */
    changePasswordNewStackBack(oldPassword, newPassword): Promise<any> {
        return new Promise((resolve, reject) => {
            this._awsService.updataPasswordNewStack(newPassword, oldPassword)
                .then(result => resolve('Password re roll back successfully'))
                .catch(error => reject(error));
        });
    }


    /**
     * changePasswordOldStack
     * @param {data: { password: string; userSecId: string; moso_member_id: string; }} data
     * @returns {Promise<any>}
     */
    changePasswordOldStack(data: {password: string; userSecId: string; moso_member_id: string;}): Promise<any> {
        if (data.moso_member_id !== null) {
            return new Promise((resolve, rejects) => {
                this.httpClient.post<any>(`${environment.apiConf.url}/cognito/user/${data.moso_member_id}/updateoldstackpassword`, data)
                    .pipe(retry(2))
                    .subscribe(
                        result => {
                            this.logger.info('changePasswordOldStack result:', result);
                            if (result) {
                                resolve(result);
                                return;
                            }
                        },
                        error => {
                            rejects(error);
                        });
            });
        } else {
            this.logger.error('No Moso Member ID provided');
        }
    }

    /**
     * getLocaStorage
     * @param name
     * @returns {any}
     */
    getLocaStorage(name): any {
        return JSON.parse(localStorage.getItem(name));
    }


}
