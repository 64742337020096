import { Component, OnInit, ViewEncapsulation, Input, OnDestroy } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Observable, Subscription } from 'rxjs';
import { PopupService } from '../../services/helpers/popup.service';
import { ToastService } from '../../services/helpers/toast.service';
import { LocalStorageService } from '../../services/helpers/local-storage.service';
import { MosoUser, CognitoUserModel } from '../../../models';
import { MemberStatesService } from '../../services/helpers/member-states.service';
import { AwsAmplifyService } from '../../services/core/aws-amplify.service';

@Component({
    selector: 'ib-top-header-navigation',
    templateUrl: './top-header-navigation.component.html',
    encapsulation: ViewEncapsulation.None
})
export class TopHeaderNavigationComponent implements OnInit, OnDestroy {
    @Input() options: any;
    user$: Observable<CognitoUserModel>;
    userMOSOData: MosoUser;
    subscriptions: Array<Subscription> = [];
    balance: string;
    balancePos: string;
    isFirstDate: boolean;
    isFirstToast: boolean;

    constructor(public _userService: UserService, public popupService: PopupService, public _awsAmplifyService: AwsAmplifyService, public toastService: ToastService, private _memberStateService: MemberStatesService) {
    }

    /*** ngOnInit **/
    ngOnInit(): void {
        this.getUserData();
        this.isFirstDate = this._userService.isFirstDate();
    }

    /*** Get User Data **/
    getUserData(): void {
        this.user$ = this._userService.user$;

        this.subscriptions.push(this._userService.userDataMoso$.subscribe(
            (data: MosoUser) => {
                if (data) {
                    this.userMOSOData = data;

                    // this._memberStateService.noActiveMembership().then(status => {
                    //     if (status) {
                    //         this._memberStateService.memberHasBalance().then(st => {
                    //             if (!st) {
                    //                 this.logOut();
                    //             }
                    //         });
                    //     }
                    // });
                    if (this.userMOSOData.Balance) {
                        this.balance = this.userMOSOData.Balance.toFixed(2);
                    }
                    if (data.Balance < 0) {
                        this.balancePos = (Number(this.userMOSOData.Balance) * -1).toFixed(2);
                    }

                    const showed = LocalStorageService.getLocalStorage('first:show');
                    if (data.Balance > 0 && !this.isFirstDate && !showed) {
                        LocalStorageService.setLocaStorage('first:show', true);
                        this.toastService.setToastVisible({
                            visible: true,
                            options: {
                                modalTitle: 'Payment Required',
                                modalMessage: `
                                <br>
                                Your Blink account is now in default.<br>

                                Pay your balance today so we can inform the Collection Agency that your account is up to date.<br>

                                Just click the <b>pay balance</b> button on the screen below.<br><br>
                            `,
                                modalButtonTitle: 'Got it',
                                modalClass: 'payment-pop-up pay-required-pop',
                                modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right'
                            }
                        });
                    }
                }
            }));
    }

    /*** Log Out User From AWS Cognito, Redirect User To Home Page **/
    logOut(): void {
        this._awsAmplifyService.signOut();
    }

    /*** ngOnDestroy **/
    ngOnDestroy(): void {
        this.clean();
    }

    /*** clear subscription array **/
    clean(): void {
        for (const num of this.subscriptions) {
            num.unsubscribe();
        }
    }

    openPopup() {
        if (Number(this.balance) > 0 && !this.isFirstDate) {
            this.popupService.setPopupVisible(true);
        }
    }

}
