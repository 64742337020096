import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { P404Component } from './public/other/404.component';
import { MainLayoutComponent } from './core/layouts/layout/main-layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { SimpleLayoutComponent } from './core/layouts/layout/simple-layout.component';
import { CheckoutLayoutComponent } from './core/layouts/layout/checkout-layout.component';
import { SuccessScreenComponent } from './secure/dashboard/shared/success-screen/success-screen.component';
import { SimpleWithMenuLayoutComponent } from './core/layouts/layout/simple-with-menu-layout.component';
import { DoNotShowOnRefreshGuard } from './core/guards/do-not-show-on-refresh.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'iblink',
        pathMatch: 'full'
    },
    {
        path: 'public',
        component: SimpleLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './public/public.module#PublicModule',
            }
        ]
    },
    {
        path: 'iblink',
        component: MainLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: './secure/secure.module#SecureModule',
            }
        ]
    },
    {
        path: 'checkout',
        component: CheckoutLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: './secure/dashboard/checkout/checkout.module#CheckoutModule',
            }
        ]
    },
    {
        path: 'success',
        component: SimpleWithMenuLayoutComponent,
        canActivate: [AuthGuard, DoNotShowOnRefreshGuard],
        canActivateChild: [AuthGuard, DoNotShowOnRefreshGuard],
        children: [
            { path: '', component: SuccessScreenComponent }
        ]
    },
    { path: '**', component: P404Component }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
