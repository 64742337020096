import {environment} from '../../../environments/environment';
export const statesList = [
    {
        'name': 'Alabama',
        'abbreviation': 'AL'
    },
    {
        'name': 'Alaska',
        'abbreviation': 'AK'
    },
    {
        'name': 'American Samoa',
        'abbreviation': 'AS'
    },
    {
        'name': 'Arizona',
        'abbreviation': 'AZ'
    },
    {
        'name': 'Arkansas',
        'abbreviation': 'AR'
    },
    {
        'name': 'California',
        'abbreviation': 'CA'
    },
    {
        'name': 'Colorado',
        'abbreviation': 'CO'
    },
    {
        'name': 'Connecticut',
        'abbreviation': 'CT'
    },
    {
        'name': 'Delaware',
        'abbreviation': 'DE'
    },
    {
        'name': 'District Of Columbia',
        'abbreviation': 'DC'
    },
    {
        'name': 'Federated States Of Micronesia',
        'abbreviation': 'FM'
    },
    {
        'name': 'Florida',
        'abbreviation': 'FL'
    },
    {
        'name': 'Georgia',
        'abbreviation': 'GA'
    },
    {
        'name': 'Guam',
        'abbreviation': 'GU'
    },
    {
        'name': 'Hawaii',
        'abbreviation': 'HI'
    },
    {
        'name': 'Idaho',
        'abbreviation': 'ID'
    },
    {
        'name': 'Illinois',
        'abbreviation': 'IL'
    },
    {
        'name': 'Indiana',
        'abbreviation': 'IN'
    },
    {
        'name': 'Iowa',
        'abbreviation': 'IA'
    },
    {
        'name': 'Kansas',
        'abbreviation': 'KS'
    },
    {
        'name': 'Kentucky',
        'abbreviation': 'KY'
    },
    {
        'name': 'Louisiana',
        'abbreviation': 'LA'
    },
    {
        'name': 'Maine',
        'abbreviation': 'ME'
    },
    {
        'name': 'Marshall Islands',
        'abbreviation': 'MH'
    },
    {
        'name': 'Maryland',
        'abbreviation': 'MD'
    },
    {
        'name': 'Massachusetts',
        'abbreviation': 'MA'
    },
    {
        'name': 'Michigan',
        'abbreviation': 'MI'
    },
    {
        'name': 'Minnesota',
        'abbreviation': 'MN'
    },
    {
        'name': 'Mississippi',
        'abbreviation': 'MS'
    },
    {
        'name': 'Missouri',
        'abbreviation': 'MO'
    },
    {
        'name': 'Montana',
        'abbreviation': 'MT'
    },
    {
        'name': 'Nebraska',
        'abbreviation': 'NE'
    },
    {
        'name': 'Nevada',
        'abbreviation': 'NV'
    },
    {
        'name': 'New Hampshire',
        'abbreviation': 'NH'
    },
    {
        'name': 'New Jersey',
        'abbreviation': 'NJ'
    },
    {
        'name': 'New Mexico',
        'abbreviation': 'NM'
    },
    {
        'name': 'New York',
        'abbreviation': 'NY'
    },
    {
        'name': 'North Carolina',
        'abbreviation': 'NC'
    },
    {
        'name': 'North Dakota',
        'abbreviation': 'ND'
    },
    {
        'name': 'Northern Mariana Islands',
        'abbreviation': 'MP'
    },
    {
        'name': 'Ohio',
        'abbreviation': 'OH'
    },
    {
        'name': 'Oklahoma',
        'abbreviation': 'OK'
    },
    {
        'name': 'Oregon',
        'abbreviation': 'OR'
    },
    {
        'name': 'Palau',
        'abbreviation': 'PW'
    },
    {
        'name': 'Pennsylvania',
        'abbreviation': 'PA'
    },
    {
        'name': 'Puerto Rico',
        'abbreviation': 'PR'
    },
    {
        'name': 'Rhode Island',
        'abbreviation': 'RI'
    },
    {
        'name': 'South Carolina',
        'abbreviation': 'SC'
    },
    {
        'name': 'South Dakota',
        'abbreviation': 'SD'
    },
    {
        'name': 'Tennessee',
        'abbreviation': 'TN'
    },
    {
        'name': 'Texas',
        'abbreviation': 'TX'
    },
    {
        'name': 'Utah',
        'abbreviation': 'UT'
    },
    {
        'name': 'Vermont',
        'abbreviation': 'VT'
    },
    {
        'name': 'Virgin Islands',
        'abbreviation': 'VI'
    },
    {
        'name': 'Virginia',
        'abbreviation': 'VA'
    },
    {
        'name': 'Washington',
        'abbreviation': 'WA'
    },
    {
        'name': 'West Virginia',
        'abbreviation': 'WV'
    },
    {
        'name': 'Wisconsin',
        'abbreviation': 'WI'
    },
    {
        'name': 'Wyoming',
        'abbreviation': 'WY'
    }
];

export const monthsList = [
        {
            name: 'January',
            short: 'Jan',
            number: '1',
            days: '31'
        },
        {
            name: 'February',
            short: 'Feb',
            number: '2',
            days: '28'
        },
        {
            name: 'March',
            short: 'Mar',
            number: '3',
            days: '31'
        },
        {
            name: 'April',
            short: 'Apr',
            number: '4',
            days: '30'
        },
        {
            name: 'May',
            short: 'May',
            number: '5',
            days: '31'
        },
        {
            name: 'June',
            short: 'Jun',
            number: '6',
            days: '30'
        },
        {
            name: 'July',
            short: 'Jul',
            number: '7',
            days: '31'
        },
        {
            name: 'August',
            short: 'Aug',
            number: '8',
            days: '31'
        },
        {
            name: 'September',
            short: 'Sep',
            number: '9',
            days: '30'
        },
        {
            name: 'October',
            short: 'Oct',
            number: '10',
            days: '31'
        },
        {
            name: 'November',
            short: 'Nov',
            number: '11',
            days: '30'
        },
        {
            name: 'December',
            short: 'Dec',
            number: '12',
            days: '31'
        }
        ];

export const constants = {
    // topHeaderNavigationItems: [
    //     {
    //         title: 'Account',
    //         link: '/iblink/info',
    //         icon: 'icon-cog'
    //     },
    //     {
    //         title: 'BFF Rewards',
    //         link: '/iblink/referrals',
    //         icon: 'icon-users'
    //     },
    //     {
    //         title: 'Personal Training',
    //         link: '/iblink/startup',
    //         icon: 'icon-dumbbell'
    //     },
    //     {
    //         title: 'FAQ',
    //         link: environment.domain + '/faq',
    //         icon: 'icon-conversations'
    //     },
    //     {
    //         title: 'LogOut',
    //         link: environment.domain + '/public/login',
    //         icon: 'icon-logout'
    //     }],
    headerMenusItems: [
        {
            title: 'iblink\n home',
            link: '/iblink/home',
            className: 'bg-blue'
        },
        {
            title: 'Member and\n login info',
            link: '/iblink/info',
            className: 'bg-green'
        },
        {
            title: 'Billing and\n payments',
            link: '/iblink/billing',
            className: 'bg-orange'
        },
        {
            title: 'BFF rewards\n refer a friend',
            link: '/iblink/referrals',
            className: 'bg-blue'
        },
        {
            title: 'Personal training\n and start-up',
            link: '/iblink/startup',
            className: 'bg-navy'
        }],
    headerMobileMenusItems: [
        {
            title: 'iblink',
            link: '/iblink/home'
        },
        {
            title: 'Member and login information',
            link: '/iblink/info'
        },
        {
            title: 'Billing and payments',
            link: '/iblink/billing'
        },
        {
            title: 'Referrals and rewards',
            link: '/iblink/referrals'
        },
        {
            title: 'Personal training and start-up',
            link: '/iblink/startup'
        }],
    menusHidden: [
        {
            title: 'Locations',
            link: environment.domain + '/locations'
        },
        {
            title: 'Personal Training',
            link: '/iblink/startup'
        },
        {
            title: 'Franchise',
            link: 'http://www.blinkfranchising.com'
        },
        {
            title: 'BFF Rewards',
            link: '/iblink/referrals'
        }],
    membershipPlans: [
        {
            content_1: '12-month agreement.',
            content_2: 'If joined after April 1st, 2019, your annual fee will be charged the 2nd day of the 3rd month. If joined before April 1st, 2019, your annual fee will be charged the 3rd day of the 3rd month.',
            list_1: [
                '60+ locations (includes Manhattan)',
                'Unlimited guest privileges'
            ],
            list_2: [
                '80+ pieces of cardio equipment',
                '50+ pieces of strength equipment',
                '40 pairs of dumbbells up to 90 lbs',
                'Locker rooms, showers with body wash/shampoo',
                'Stretch/functional area',
                'Free 30-minute Start-Up Session',
                '1-on-1 personal training',
                'Referral and rewards program'
            ]
        },
        {
            content_1: '12-month agreement.',
            content_2: '$49 annual fee is charged on the 3rd day of the 2nd month after joining.<br><br> Excludes 54th, Bryant Park, East Village, Chelsea, Grand Central, NoHo, FiDi, Murray Hill, Penn Station.',
            list_1: [
                '60+ locations (includes Manhattan)',
                'Unlimited guest privileges'
            ],
            list_2: [
                '80+ pieces of cardio equipment',
                '50+ pieces of strength equipment',
                '40 pairs of dumbbells up to 90 lbs',
                'Locker rooms, showers with body wash/shampoo',
                'Stretch/functional area',
                'Free 30-minute Start-Up Session',
                '1-on-1 personal training',
                'Referral and rewards program'
            ]
        },
        {
            content_1: 'No commitment.  Cancel with 45 days notice.',
            content_2: '$49 annual fee is charged on the 3rd day of the 2nd month after joining.',
            list_1: [
                '1 location',
            ],
            list_2: [
                '80+ pieces of cardio equipment',
                '50+ pieces of strength equipment',
                '40 pairs of dumbbells up to 90 lbs',
                'Locker rooms, showers with body wash/shampoo',
                'Stretch/functional area',
                'Free 30-minute Start-Up Session',
                '1-on-1 personal training',
                'Referral and rewards program'
            ]
        }],
    footerMenus: [
        {
            title: 'Corporate Program',
            link: '/iblink/home?modal=corporate'
        },
        // {
        //     title: 'Tools & Tips',
        //     link: environment.domain + '/workouts'
        // },
        {
            title: 'Franchising',
            link: 'https://www.blinkfranchising.com'
        },
        // {
        //     title: 'Landlords & Brokers',
        //     link: environment.domain + '/Landlord'
        // },
        {
            title: 'Privacy Policy',
            link: environment.domain + '/privacy'
        },
        {
            title: 'Terms Of Use',
            link: environment.domain + '/terms'
        },
        // {
        //     title: 'Blink in the news',
        //     link: environment.domain + '/news'
        // }
        ],
    appointmentTimeList: [
        {
            name: 'Morning',
            value: 'Morning'
        },
        {
            name: 'Afternoon',
            value: 'Afternoon'
        },
        {
            name: 'Evening',
            value: 'Evening'
        },
        {
            name: 'Anytime',
            value: 'Anytime'
        }
    ],
    fitnessGoalList: [
        {
            name: 'Lose Weight',
            value: 'Lose Weight'
        },
        {
            name: 'Improve Strength',
            value: 'Improve Strength'
        },
        {
            name: 'Boost Confidence',
            value: 'Boost Confidence'
        },
        {
            name: 'Stay Active',
            value: 'Stay Active'
        },
        {
            name: 'Upcoming Event',
            value: 'Upcoming Event'
        },
        {
            name: 'Other',
            value: 'Other'
        }
    ],
    gymLocationList: [
        {
            name: 'Blink 116th',
            value: '619',
            gymEmail: '116street@blinkfitness.com'
        },
        {
            name: 'Blink 125th Street',
            value: '610',
            gymEmail: '125street@blinkfitness.com'
        },
        {
            name: 'Blink 54th',
            value: '645',
            gymEmail: '54thstreet@blinkfitness.com'
        },
        {
            name: 'Blink Astoria',
            value: '624',
            gymEmail: 'astoria@blinkfitness.com'
        },
        {
            name: 'Blink Baldwin',
            value: '631',
            gymEmail: 'baldwin@blinkfitness.com'
        },
        {
            name: 'Blink Boerum Hill',
            value: '638',
            gymEmail: 'boerumhill@blinkfitness.com'
        },
        {
            name: 'Blink Brentwood',
            value: '616',
            gymEmail: 'brentwood@blinkfitness.com'
        },
        {
            name: 'Blink Bryant Park',
            value: '634',
            gymEmail: 'bryantpark@blinkfitness.com'
        },
        {
            name: 'Blink Bushwick',
            value: '650',
            gymEmail: 'bushwick@blinkfitness.com'
        },
        {
            name: 'Blink Canarsie',
            value: '643',
            gymEmail: 'canarsie@blinkfitness.com'
        },
        {
            name: 'Blink Clifton',
            value: '639',
            gymEmail: 'clifton@blinkfitness.com'
        },
        {
            name: 'Blink Concourse Village',
            value: '618',
            gymEmail: 'concoursevillagewest@blinkfitness.com'
        },
        {
            name: 'Blink Coney Island',
            value: '647',
            gymEmail: 'coneyisland@blinkfitness.com'
        },
        {
            name: 'Blink Flatbush',
            value: '613',
            gymEmail: 'flatbush@blinkfitness.com'
        },
        {
            name: 'Blink Gates',
            value: '628',
            gymEmail: 'gates@blinkfitness.com'
        },
        {
            name: 'Blink Grand Central',
            value: '649',
            gymEmail: 'grandcentral@blinkfitness.com'
        },
        {
            name: 'Blink Grand Concourse',
            value: '621',
            gymEmail: 'grandconcourse@blinkfitness.com'
        },
        {
            name: 'Blink Hicksville',
            value: '648',
            gymEmail: 'hicksville@blinkfitness.com'
        },
        {
            name: 'Blink Islandia',
            value: '612',
            gymEmail: 'islandia@blinkfitness.com'
        },
        {
            name: 'Blink Journal Square',
            value: '625',
            gymEmail: 'journalsquare@blinkfitness.com'
        },
        {
            name: 'Blink Junction',
            value: '606',
            gymEmail: 'nostrand@blinkfitness.com'
        },
        {
            name: 'Blink Linden',
            value: '640',
            gymEmail: 'linden@blinkfitness.com'
        },
        {
            name: 'Blink Lindenhurst',
            value: 'FNY302',
            gymEmail: 'Lindenhurst@blinkfitness.com'
        },
        {
            name: 'Blink Macombs',
            value: '636',
            gymEmail: 'macombs@blinkfitness.com'
        },
        {
            name: 'Blink Melville',
            value: '607',
            gymEmail: 'melville@blinkfitness.com'
        },
        {
            name: 'link Murray Hill',
            value: '611',
            gymEmail: 'murrayhill@blinkfitness.com'
        },
        {
            name: 'Blink Nassau St',
            value: '642',
            gymEmail: 'nassau@blinkfitness.com'
        },
        {
            name: 'Blink NoHo',
            value: '601',
            gymEmail: 'noho@blinkfitness.com'
        },
        {
            name: 'Blink Ozone Park',
            value: '646',
            gymEmail: 'ozonepark@blinkfitness.com'
        },
        {
            name: 'Blink Paramus',
            value: '603',
            gymEmail: 'paramus@blinkfitness.com'
        },
        {
            name: 'Blink Parkchester',
            value: '605',
            gymEmail: 'parkchester@blinkfitness.com'
        },
        {
            name: 'Blink Parsippany',
            value: '626',
            gymEmail: 'parsippany@blinkfitness.com'
        },
        {
            name: 'Blink Riverdale',
            value: '644',
            gymEmail: 'riverdale@blinkfitness.com'
        },
        {
            name: 'Blink Roosevelt',
            value: '629',
            gymEmail: 'roosevelt@blinkfitness.com'
        },
        {
            name: 'Blink Selden',
            value: '615',
            gymEmail: 'selden@blinkfitness.com'
        },
        {
            name: 'Blink Southern Boulevard',
            value: '657',
            gymEmail: 'southernboulevard@blinkfitness.com'
        },
        {
            name: 'Blink St Anns',
            value: '608',
            gymEmail: 'stanns@blinkfitness.com'
        },
        {
            name: 'Blink Union',
            value: '637',
            gymEmail: 'union@blinkfitness.com'
        },
        {
            name: 'Blink Valley Stream',
            value: '641',
            gymEmail: 'valleystream@blinkfitness.com'
        },
        {
            name: 'Blink West Islip',
            value: '614',
            gymEmail: 'westislip@blinkfitness.com'
        },
        {
            name: 'Blink Williamsburg',
            value: '658',
            gymEmail: 'williamsburg@blinkfitness.com'
        },
        {
            name: 'Blink Willingboro',
            value: '663',
            gymEmail: 'willingboro@blinkfitness.com'
        },
        {
            name: 'Blink Wissinoming',
            value: '666',
            gymEmail: 'wissinoming@blinkfitness.com'
        },
        {
            name: 'Blink White Plains',
            value: '617',
            gymEmail: 'whiteplains@blinkfitness.com'
        },
        {
            name: 'Blink Wooside',
            value: '652',
            gymEmail: 'woodside@blinkfitness.com'
        },
        {
            name: 'Blink Yonkers',
            value: '602',
            gymEmail: 'yonkers@blinkfitness.com'
        },
        {
            name: 'Blink Gardena',
            value: '673',
            gymEmail: 'gardena@blinkfitness.com'
        }
    ],
    workout: {
        monthlyWorkoutUrl: 'https://media2.blinkfitness.com/cms/images/B1878024-BC89-44AC-9F1A-88FA2EFF0AF3/en-us/TheHAPS_WORKOUT_MENU_SPRING2018.pdf'
    },
    officialRules: [
        'Members may refer up to 25 of their friends at a time. In our BFF Rewards program, we refer to referred friends as "BFFs (Blink Fitness Friends)".',
        'Friends you refer must join Blink within 60 days of their referral date to generate a reward. When signing up, they must also use the email address to which their referral invitation was sent.',
        'Once friends you’ve referred join, they must remain members for 62 days before a reward is issued. After 62 days, the reward will automatically be applied towards your next month’s dues.',
        'Reward credit is non transferable and cannot be redeemed for cash or used towards any retail or personal training.',
        'Any reward credit on account will be applied to membership billing only.',
        'All participants must be existing month-to-month members who are up-to-date on their dues to receive referral reward credit.',
        'Blink and Equinox employees and other people with complimentary memberships are excluded from the program.',
        'You may not use any robotic, automated or programmed methods or engage in spamming, and Blink Fitness reserves the right to deny a referral credit to, disqualify from the referrals program, or terminate the membership of any individual it believes is tampering with the functioning or otherwise abusing the referrals program.'
    ], PATTERNS : {
        EMAIL: /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/,
        ZIP: '[0-9]+[A-Z]?',
        PHONE: '\\d{3}([ .-])?\\d{3}([ .-])?\\d{4}|\\(\\d{3}\\)([ ])?\\d{3}([.-])?\\d{4}|\\(\\d{3}\\)([ ])?\\d{3}([ ])?\\d{4}|\\(\\d{3}\\)([.-])?\\d{3}([.-])?\\d{4}|\\d{3}([ ])?\\d{3}([ .-])?\\d{4}',
        BIRTHDAY: '^(0?[1-9]|1[0-2])[\/](0?[1-9]|[12]\d|3[01])[\/](19|20)\d{2}$',
        CORPORATE_MEMBERSHIP_TEXT: /^[0-9A-Za-z _."\-']+$/
    }
};





