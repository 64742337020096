import { AfterViewInit, Component, OnInit, ViewEncapsulation, Input, Output, OnChanges, SimpleChanges, SimpleChange, OnDestroy } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { UserService } from '../../../../core/services/user.service';
import { Subscription } from 'rxjs';
import { User } from '../../../../models/user';

declare let $: any;
declare let _satellite: any;

@Component({
    selector: 'ib-welcome-popup',
    templateUrl: './welcome-popup.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./welcome-popup.component.scss']
})

export class WelcomePopupComponent implements OnInit, OnDestroy {
    public user: User;
    public subscriptions: Array<Subscription> = [];
    public popupType: boolean;
    public startPopup: boolean;

    constructor(public _userService: UserService) {
    }

    ngOnInit() {
        this._userService.getCognitoUserData();
        this.subscriptions.push(this._userService.user$.subscribe((data: User) => {
            this.user = data;
        }));
        this.popupType = false;
    }


    onClick() {
    }

    ngOnDestroy(): void {
        for (const num of this.subscriptions) {
            num.unsubscribe();
        }
    }

}
