import {Injectable} from '@angular/core';
import {LoggerService} from '../log4ts/logger.service';
import {CognitoUser, CognitoUserPool, CookieStorage, CognitoUserSession, AuthenticationDetails} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';
import 'amazon-cognito-js';
import * as _ from 'lodash';
import {environment} from '../../../../environments/environment';

declare let $: any;


@Injectable()
export class AwsService {
    public static storage = {
        domain: environment.awsConfig.storageDomain,
        expires: 1,
        secure: false
    };
    public static _POOL_DATA: any = {
        UserPoolId: environment.awsConfig.UserPoolId,
        ClientId: environment.awsConfig.ClientId,
        Storage: new CookieStorage(AwsService.storage)
    };

    cognitoUser: CognitoUser;
    cognitoSession: CognitoUserSession;
    idenityCredentials: AWS.CognitoIdentityCredentials;
    idenityPermissions: Promise<any>;

    constructor(public logger: LoggerService) {
        this.logger.info('-- AwsService init --');
        AWS.config.region = environment.awsConfig.region;
    }

    /**
     * getAuthorizationHeaders(request): any
     * @param request
     * @returns {any}
     */
    getAuthorizationHeaders(request): Promise<any> {
        return new Promise((resolve) => {
            this.idenityPermissions.then(res => {
                if (res.identityId) {
                    const httpRequest = new (<any>AWS).HttpRequest(
                        request.url,
                        environment.awsConfig.APIregion
                    );
                    httpRequest.method = request.method;
                    httpRequest.headers.host = environment.apiConf.domain;
                    if (request.method !== 'GET') {
                        httpRequest.body = request.body ? JSON.stringify(request.body) : null;
                    }
                    httpRequest.headers['Content-Type'] = environment.apiConf.contentType;
                    httpRequest.headers[environment.apiConf.keyName] = environment.apiConf.keyValue;
                    const v4signer = new (<any>AWS).Signers.V4(httpRequest, 'execute-api', true);
                    v4signer.addAuthorization(res, (<any>AWS).util.date.getDate());

                    const headers = {};
                    for (const key of Object.keys(v4signer.request.headers)) {
                        if (key !== 'host') {
                            headers[key] = v4signer.request.headers[key];
                        }
                    }
                    resolve(headers);
                }
            });
        });
    }

    /**
     * Getting Cognito Current User
     * */
    getCurrentCognitoUser(): CognitoUser | null {
        return this.getCognitoUserPool().getCurrentUser();
    }

    /**
     * Getting Cognito User Pool
     * */
    getCognitoUserPool(): CognitoUserPool | null {
        return new CognitoUserPool(AwsService._POOL_DATA);
    }

    // createCognitoUser(user_email): CognitoUser {
    //     if (user_email !== '') {
    //         const userData = {
    //             Username: user_email,
    //             Pool: this.getCognitoUserPool(),
    //             Storage: new CookieStorage(AwsService.storage)
    //         };
    //         return this.cognitoUser = new CognitoUser(userData);
    //     }
    // }

    // logIn(authenticationDetails: any) {
    //     const self = this;
    //     return new Promise((resolve, reject) => {
    //         this.cognitoUser.authenticateUser(authenticationDetails, {
    //             onSuccess: function (result) {
    //                 self.checkIdentityCredentials();
    //                 resolve(true);
    //             },
    //
    //             onFailure: function (err) {
    //                 self.logger.warn('AWS.service login Fail error', err);
    //                 reject(err);
    //             }
    //         });
    //     });
    // }

    confirmPassword(confirmation_code: string, password: string) {
        const self = this;
        return new Promise((resolve, reject) => {
            this.cognitoUser.confirmPassword(confirmation_code, password, {
                onSuccess() {
                    resolve(true);
                },
                onFailure(err) {
                    self.logger.error('New Password didn\'t confirmed for new iBlink! Please try again.', err);
                    reject(err);
                }
            });
        });
    }


    /**
     * Congnito User Get Current user status
     * */
    isLoggedIn(): Promise<boolean> {
        const cognitoUser = this.getCurrentCognitoUser();
        return new Promise((resolve, reject) => {
            if (cognitoUser != null) {
                cognitoUser.getSession((err: any, session: any) => {
                    if (err) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
            } else {
                resolve(false);
            }
        });
    }

    /**
     * Identity Credentials
     * */
    checkIdentityCredentials(cb = null): void {
        this.cognitoUser = this.getCurrentCognitoUser();
        if (this.cognitoUser !== null) {
            this.getCognitoSession()
                .then((session: CognitoUserSession) => {
                    this.cognitoSession = session;
                    return this.setIdentityCredentials();
                })
                .then(() => {
                    // this.logger.info('Identity Credentials setted');
                    return this.idenityPermissions = this.getIdenityCredentials();
                })
                .then(() => {
                    this.setIdentityDataSet();
                    if (cb) {
                        console.log('Execute callback');
                        cb();
                    }
                })
                .catch(error => this.logger.info(error));
        }
    }

    /**
     * Identity Credentials
     * */
    checkIdentityCredentialsP(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.cognitoUser = this.getCurrentCognitoUser();
            if (this.cognitoUser !== null) {
                this.getCognitoSession()
                    .then((session: CognitoUserSession) => {
                        this.cognitoSession = session;
                        this.idenityPermissions = Promise.resolve({
                            identityId: undefined,
                            accessKeyId: undefined,
                            secretAccessKey: undefined,
                            sessionToken: undefined
                        });
                        return this.setIdentityCredentials();
                    })
                    .then(() => {
                        resolve();
                    })
                    .catch(error => {
                        this.logger.info(error);
                        //reject(error);
                    });
            } else {
                reject('cognito user is null');
            }
        });
    }

    removeAllCookiesUnderDomain(): void {
        const cookies = document.cookie.split(';'),
            domain_name = environment.awsConfig.storageDomain;

        cookies.forEach((cooky) => {
            const equals = cooky.indexOf('='),
                name = equals > -1 ? cooky.substr(0, equals) : cooky;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=' + domain_name + ';path=/';
        });
    }


    /**
     * Congnito User Sign Out Method with clear local Cached Data
     * globalSignOut({onSuccess: (msg) => {}; onFailure: (err) => {}})
     * clearCachedId()
     * window.location.replace('https://qa.blinkfitness.com/v1/users/logout');
     * */
    signOut() {
        this.cognitoUser.globalSignOut({
            onSuccess: msg => {
                if (this.idenityCredentials) {
                    this.idenityCredentials.clearCachedId();
                }
                this.removeAllCookiesUnderDomain();
                localStorage.clear();
                // window.location.replace(environment.redirectUrl.logOut);
            },
            onFailure: err => {
                this.removeAllCookiesUnderDomain();
                localStorage.clear();
                this.logger.error(err);
                // window.location.replace(environment.redirectUrl.logOut);
            }
        });

    }

    /**
     * Get Congnito Cognito Session
     * */
    getCognitoSession() {
        if (this.cognitoUser !== null) {
            return new Promise((resolve, reject) => {
                this.cognitoUser.getSession((err, session) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(session);
                    }
                });
            });
        }
        return null;
    }

    /**
     * Get Idenity Credentials
     * */
    getIdenityCredentials(): Promise<any> {
        if (this.cognitoSession.isValid()) {
            return new Promise(resolve => {
                this.idenityCredentials.get(() => {
                    const permissions = {
                        identityId: this.idenityCredentials.identityId,
                        accessKeyId: this.idenityCredentials.accessKeyId,
                        secretAccessKey: this.idenityCredentials.secretAccessKey,
                        sessionToken: this.idenityCredentials.sessionToken
                    };
                    resolve(permissions);
                });
            });
        }
    }

    /**
     * Get Idenity Credentials
     * */
    getIdenityCredentialsP(): Promise<any> {
        if (this.cognitoSession.isValid()) {
            return new Promise((resolve, reject) => {
                this.idenityCredentials.get((err) => {
                    /*if (err) {
                        reject(err);
                    }*/
                    const permissions = {
                        identityId: this.idenityCredentials.identityId,
                        accessKeyId: this.idenityCredentials.accessKeyId,
                        secretAccessKey: this.idenityCredentials.secretAccessKey,
                        sessionToken: this.idenityCredentials.sessionToken
                    };
                    resolve(permissions);
                });
            });
        }
    }

    /**
     * Set Identity Credentials
     * */
    setIdentityCredentials(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.cognitoSession.isValid()) {
                const aws_login = 'cognito-idp.' + environment.awsConfig.UserPoolIdRegion + '.amazonaws.com/' + environment.awsConfig.UserPoolId;
                const linked_logins = {};
                linked_logins[aws_login] = this.cognitoSession.getIdToken().getJwtToken();
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: environment.awsConfig.IdentityPoolId, // your identity pool id here
                    Logins: linked_logins
                });
                this.idenityCredentials = <AWS.CognitoIdentityCredentials>AWS.config.credentials;
                resolve(true);
            } else {
                reject('Cognito Session is inValid');
            }

        });
    }

    /**
     * verifyUserEmail(email)
     * **/
    //TODO: please updata region
    verifyUserEmail(email): void {
        this.idenityPermissions.then(res => {
            const client_params = {
                apiVersion: '2016-04-19',
                region: 'us-east-1' // change region if required
            };
            const client = new AWS.CognitoIdentityServiceProvider(<any>client_params);
            client.adminUpdateUserAttributes(
                {
                    UserAttributes: [
                        {
                            Name: 'email_verified',
                            Value: 'true'
                        }
                    ],
                    UserPoolId: environment.awsConfig.UserPoolId,
                    Username: email
                },
                err => {
                    if (err) {
                        this.logger.info(err, err.stack);
                    } else {
                    }
                }
            );
        });
    }


    verifyUserEmailP(email): Promise<any> {
        return new Promise((resolve, reject) => {
            const client_params = {
                apiVersion: '2016-04-19',
                region: 'us-east-1' // change region if required
            };
            const client = new AWS.CognitoIdentityServiceProvider(<any>client_params);
            client.adminUpdateUserAttributes(
                {
                    UserAttributes: [
                        {
                            Name: 'email_verified',
                            Value: 'true'
                        }
                    ],
                    UserPoolId: environment.awsConfig.UserPoolId,
                    Username: email
                },
                err => {
                    if (err) {
                        this.logger.info(err, err.stack);
                        reject(err);
                    } else {
                        resolve();
                    }
                }
            );
        });
    }

    getCurrentIdentityDataSetState(dataset: any): Promise<any> {
        return new Promise((resolve) => {
            dataset.synchronize({
                onSuccess: (data, newRecords) => {
                    // Display Welcome Pop up if never displayed before.
                    // dataset.get('WelcomePopUp', (error, value) => {
                    // $('#welcomePopup').modal('show');
                    /*if (!value) {
                     $('#welcomePopup2').modal('show');
                     dataset.put('WelcomePopUp', '1', (_error, record) => {
                     this.logger.info('dataset put Welcome PopUp error', _error);
                     });
                     resolve(true);
                     } else {
                     $('#welcomePopup2').modal('hide');
                     resolve(true);
                     }
                     if (error) {
                     this.logger.warn('dataset get WelcomePopUp error', error);
                     }*/
                    // });
                    resolve(true);
                }
            });
        });
    }

    /**
     * getDataSetData
     * @param {string} itemName
     * @returns {Promise<any>}
     */
    getDataSetData(itemName: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.idenityCredentials.identityId) {
                const syncClient = new (<any>AWS).CognitoSyncManager();
                syncClient.openOrCreateDataset('iBlinkMember', (err, dataset) => {
                    if (!err) {
                        dataset.get(itemName, (error, value) => {
                            if (error) {
                                reject(error);
                            } else {
                                if (value && value !== '') {
                                    resolve(value);
                                } else {
                                    reject(value);
                                }
                            }
                        });
                    } else {
                        resolve('Cannot get syncClient.openOrCreateDataset');
                        this.logger.error('Cannot get syncClient.openOrCreateDataset');
                    }

                });
            }
        });
    }

    /**
     * setDataSetData
     * @param {string} itemName
     * @param {string} val
     * @returns {Promise<any>}
     */
    setDataSetData(itemName: string, val: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.idenityCredentials.identityId) {
                const syncClient = new (<any>AWS).CognitoSyncManager();
                syncClient.openOrCreateDataset('iBlinkMember', (err, dataset) => {
                    if (!err) {
                        dataset.put(itemName, val, (error, value) => {
                            if (value) {
                                resolve(value);
                            }
                            if (error) {
                                reject(error);
                            }
                        });
                    } else {
                        reject('Cannot get syncClient.openOrCreateDataset');
                        this.logger.error('Cannot get syncClient.openOrCreateDataset');
                    }

                });
            }
        });
    }

    setCognitoDatasetItem(itemName: string, itemValue: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.idenityCredentials.identityId) {
                const syncClient = new (<any>AWS).CognitoSyncManager();
                syncClient.openOrCreateDataset('iBlinkMember', (err, dataset) => {
                    if (!err) {
                        dataset.get(itemName, (error, value) => {
                            if (error || !value) {
                                //reject(error);
                                dataset.put(itemName, itemValue.toString(), (error, value) => {
                                    if (value) {
                                        resolve(value);
                                    }
                                    if (error) {
                                        reject(error);
                                    }
                                });
                            } else {
                                resolve(value);
                            }
                        });
                    } else {
                        resolve('Cannot get syncClient.openOrCreateDataset');
                        this.logger.error('Cannot get syncClient.openOrCreateDataset');
                    }

                });
            }
        });
    }


    synchronizeDataset(): Promise<any> {

        return new Promise((resolve, reject) => {
            if (this.idenityCredentials.identityId) {
                const syncClient = new (<any>AWS).CognitoSyncManager({log: console.log});
                syncClient.openOrCreateDataset('iBlinkMember', (err, dataset) => {
                    if (!err) {
                        dataset.synchronize({
                            onSuccess: function (dataset, newRecords) {
                                resolve(dataset);
                            },

                            onFailure: function (err) {
                                reject(err);
                            },

                            onConflict: function (dataset, conflicts, callback) {

                                var resolved = [];

                                for (var i = 0; i < conflicts.length; i++) {
                                    resolved.push(conflicts[i].resolveWithLocalRecord());
                                }

                                dataset.resolve(resolved, function () {
                                    return callback(true);
                                });

                            },
                            onDatasetDeleted: function (dataset, datasetName, callback) {

                                // Return true to delete the local copy of the dataset.
                                // Return false to handle deleted datasets outsid ethe synchronization callback.

                                return callback(true);

                            },

                            onDatasetsMerged: function (dataset, datasetNames, callback) {

                                // Return true to continue the synchronization process.
                                // Return false to handle dataset merges outside the synchroniziation callback.

                                return callback(false);

                            }

                        });

                        let tries = 0;
                        const check = () => {

                            if (tries > 10) {
                                resolve();
                            }

                            syncClient.openOrCreateDataset('iBlinkMember', (err, dataset) => {

                                if (!err) {
                                    dataset.get('Barcode', (err, value) => {
                                        tries += 1;
                                        if (err) {
                                            reject(err);
                                        } else {
                                            if (!value) {
                                                setTimeout(check, 150);
                                            } else {
                                                resolve(true);
                                            }
                                        }
                                    });
                                } else {
                                    reject(err);
                                }

                            });
                        };

                        check();

                    } else {
                        resolve('Cannot get syncClient.openOrCreateDataset');
                        this.logger.error('Cannot get syncClient.openOrCreateDataset');
                    }

                });
            }
        });

    }

    /**
     * Set Idenity Data Set
     * */
    setIdentityDataSet(): void {
        const self = this;
        if (this.idenityCredentials.identityId) {
            const syncClient = new (<any>AWS).CognitoSyncManager();
            const verifyUserEmail = this.verifyUserEmail;
            this.getCognitoUserAttributes()
                .then(attrs => {
                    const email = attrs['email'];
                    const MoSoID = attrs['custom:moso_member_id'];
                    const identityId = attrs['sub'];
                    syncClient.openOrCreateDataset('iBlinkMember', (err, dataset) => {
                        this.getCurrentIdentityDataSetState(dataset)
                            .then(() => {
                                dataset.put('UserPoolId', identityId, () => {
                                });
                                dataset.put('MoSoID', MoSoID, () => {
                                });
                                dataset.put('email', email, () => {
                                });
                                dataset.synchronize({
                                    onSuccess: (data, newRecords) => {
                                        //verifyUserEmail(email);
                                    }
                                });

                            });

                    });
                })
                .catch(error => {
                    this.logger.info(error);
                });
        }
    }

    /**
     * Get Cognito User Attributes
     * */
    getCognitoUserAttributes(): Promise<any> {
        const userFormated: any = {};
        return new Promise((resolve, reject) => {
            if (this.cognitoUser !== null) {
                this.cognitoUser.getSession((err, session) => {
                    if (err) {
                        this.logger.error('getSession -> getCognitoUserAttributes', err);
                        return;
                    }
                    if (session.isValid()) {
                        this.cognitoUser.getUserAttributes((error, result) => {
                            if (error) {
                                reject(err);
                            } else {
                                // this.logger.info(result);
                                _.forEach(result, (value, key) => {
                                    const prop: any = value.getName();
                                    userFormated[prop] = value.getValue();
                                });
                                resolve(userFormated);
                            }
                        });
                    }
                });
            } else {
                reject('Cognito User not authorized');
            }
        });
    }

    /**
     * Set Identity Credentials
     * */
    setUnauthIdentityCredentials(): Promise<any> {
        return new Promise((resolve, reject) => {
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: environment.awsConfig.IdentityPoolId, // your identity pool id here
                Logins: {}
            });
            // Save Credentials wrapper to use it later.
            // TypeScript Issue: https://github.com/aws/amazon-cognito-js/issues/56#issuecomment-334321094
            this.idenityCredentials = <AWS.CognitoIdentityCredentials>AWS.config
                .credentials;
            resolve(true);
        });
    }

    sendContactUsEmail(message, snsTopic) {
        const sns = new AWS.SNS({region: environment.awsConfig.APIregion});
        return sns.publish({
            Message: JSON.stringify({
                'default': JSON.stringify(message)
            }),
            MessageStructure: 'json',
            TopicArn: snsTopic
        }).promise();
    }

    /**
     * updataPasswordNewStack
     * @param {string} oldPassword
     * @param {string} newPassword
     * @returns {Promise<any>}
     */
    updataPasswordNewStack(oldPassword: string, newPassword: string): Promise<any> {
        const cognitoUser = this.getCurrentCognitoUser();
        return new Promise((resolve, reject) => {
            this.authenticateUser(oldPassword, cognitoUser)
                .then(res => {
                    cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
                            if (err) {
                                reject(err);
                                return;
                            }
                            resolve(result);
                        }
                    );
                }).catch(err => reject(err)
            );
        });
    }

    /**
     * authenticate User In Cognito
     * @param {string} oldPassword
     * @param {"amazon-cognito-identity-js".CognitoUser} cognitoUser
     * @returns {Promise<"amazon-cognito-identity-js".CognitoUserSession | any>}
     */
    authenticateUser(oldPassword: string, cognitoUser: CognitoUser): Promise<CognitoUserSession | any> {
        const authData = {Username: cognitoUser.getUsername(), Password: oldPassword};
        const authDetails = new AuthenticationDetails(authData);
        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authDetails, {
                onSuccess: result => resolve(result),
                onFailure: error => reject(error)
            });
        });
    }

}
