import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { constants } from '../../const/constants';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { CognitoUserModel, MosoUser, CreditCard } from '../../../models';
import { PaymentService } from '../../services/payment.service';
import { PtService } from '../../services/pt.service';
import { MemberStatesService } from '../../services/helpers/member-states.service';
import * as moment from 'moment';

declare let $: any;
export const d = new Date();

@Component({
    selector: 'ib-left-side-bar',
    templateUrl: './left-side-bar.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LeftSideBarComponent implements OnInit, OnDestroy {

    subscriptions: Array<Subscription> = [];
    plan: any;
    user: CognitoUserModel;
    planDetails: any;
    showTopAccordionLabel: boolean;
    isCardExpired: boolean;
    balance: string | number = 0;
    currentMonth: string | number = moment().add(1, 'month').format('MM');
    currentYear: string | number = moment().add(1, 'year').format('YY');
    isHidden = false;
    mosoUser: any;
    memberHasNoActiveMembership: boolean;

    constructor(public _userService: UserService, public _paymentService: PaymentService, public ptService: PtService, private _memberStateService: MemberStatesService) {
    }


    /**
     * ngOnInit(): void
     */
    ngOnInit(): void {
        this.getUserData();
        this.showTopAccordionLabel = true;

    }

    //TODO: Need to refactor that part. Logic should grab membership using foreach and membership status / type.
    setPlan(data: MosoUser): void {
        let content_1, content_2, list_1, list_2;
        const agreementName = data.MembershipAgreements ? data.MembershipAgreements[0].AgreementName : null;
        if (agreementName) {
            switch (agreementName) {
                case 'Green':
                    // bgClassName = 'bg-green';
                    // colorClassName = 'text-green';
                    content_1 = constants.membershipPlans[0].content_1;
                    content_2 = constants.membershipPlans[0].content_2;
                    list_1 = constants.membershipPlans[0].list_1;
                    list_2 = constants.membershipPlans[0].list_2;
                    break;
                case 'Blue':
                    // bgClassName = 'bg-blue';
                    // colorClassName = 'text-blue';
                    content_1 = constants.membershipPlans[1].content_1;
                    content_2 = constants.membershipPlans[1].content_2;
                    list_1 = constants.membershipPlans[1].list_1;
                    list_2 = constants.membershipPlans[1].list_2;
                    break;
                case 'Gray':
                    // bgClassName = 'bg-navy';
                    // colorClassName = 'text-gray';
                    content_1 = constants.membershipPlans[2].content_1;
                    content_2 = constants.membershipPlans[2].content_2;
                    list_1 = constants.membershipPlans[2].list_1;
                    list_2 = constants.membershipPlans[2].list_2;
                    break;
                default:
                    this.plan = false;
                    this.isHidden = true;
            }
            this.plan = {
                title: 'Membership Plan: ' + agreementName.toLowerCase(),
                // bgClassName: bgClassName,
                // colorClassName: colorClassName,
                content_1: content_1,
                content_2: content_2,
                list_1: list_1,
                list_2: list_2
            };
        }
    }


    /**
     * getUserData(): void
     * Getting User data, getting MOSO user data
     */
    getUserData(): void {
        this.subscriptions.push(this._userService.user$.subscribe((data: CognitoUserModel) => this.user = data));
        this.subscriptions.push(this._userService.userDataMoso$.subscribe((mosoUser: MosoUser) => {
            if (mosoUser && mosoUser.RoleId) {
                this.mosoUser = mosoUser;
                this._memberStateService.inactiveMembershipStatus().then(status => {
                    this.memberHasNoActiveMembership = status;
                });
                this.setPlan(mosoUser);
                if (mosoUser.Balance && mosoUser.Balance > 0) {
                    this.balance = mosoUser.Balance.toFixed(2);
                }
                this._paymentService.creditCards.subscribe(
                    (card: CreditCard[]) => {
                        if (card) {
                            const expirationMonth = Number(card[0].ExpirationMonth);
                            const expirationYear = Number(card[0].ExpirationYear);
                            if (expirationMonth && expirationYear) {
                                this.isCardExpired = this.checkCardExpired(expirationMonth, expirationYear);
                            }
                        }
                    });
            }
        }));
    }


    /**
     * checking if credit card exp. And set current month and current Year
     * checkCardExpired(cardMonth, cardYear): boolean
     * @param cardMonth
     * @param cardYear
     * @returns {boolean}
     */
    checkCardExpired(cardMonth, cardYear): boolean {
        // const d = new Date();
        // this.currentMonth = Number(d.getMonth()) + 1;
        // this.currentYear = d.getFullYear();
        return this.currentYear > cardYear || (this.currentYear === cardYear && this.currentMonth > cardMonth);

    }

    /**
     * Showing popover
     * popover(): void
     */
    popover(): void {
        $('[data-toggle="popover"]').popover('show');
    }

    /*** ngOnDestroy **/
    ngOnDestroy(): void {
        this.clean();
    }

    /*** clear subscription **/
    clean(): void {
        for (const num of this.subscriptions) {
            num.unsubscribe();
        }
    }

    onMyPersonalTraining() {
        this.ptService.myPersonalTraining();
    }

    /**
     * isMosoMemberHasJuniorGrayPlan(): boolean
     * @returns {boolean}
     */
    isMosoMemberHasCancellationDate(): boolean | void {
        if (this.mosoUser && this.mosoUser.RoleId) {
            return this.mosoUser.MembershipAgreements ? !!this.mosoUser.MembershipAgreements[0].CancellationDate : null;
        }
    }
}


