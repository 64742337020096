import { AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { constants } from '../../const/constants';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'ib-main-layout',
    templateUrl: './main-layout.component.html',
    encapsulation: ViewEncapsulation.None
})
export class MainLayoutComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {
    public headerMobileMenu: any;
    public leftSidebarVisible: boolean;
    subscription: Subscription;

    constructor(public userService: UserService) {
    }

    ngOnInit() {
        this.headerMobileMenu = constants.headerMobileMenusItems;
        if (window.screen.width < 768) {
            this.userService.setLeftSidebarVisible(false);
        }
        this.subscription = this.userService.leftSidebarVisible.subscribe(data => {
            this.leftSidebarVisible = data;
        });

    }

    ngAfterViewInit() {
    }

    ngAfterViewChecked() {
    }


    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}



