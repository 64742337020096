import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpsRequestInterceptor } from './services/core/http.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
/**
 * Custom Created services
 * */
import { AmplifyService } from 'aws-amplify-angular';
import { ConsoleLoggerService } from './services/log4ts/console-logger.service';
import { LoggerService } from './services/log4ts/logger.service';
import { AwsService } from './services/core/aws.service';
import { HttpService } from './services/core/http.service';
import { AuthGuard } from './guards/auth.guard';
import { UserService } from './services/user.service';
import { PaymentService } from './services/payment.service';
import { AnalyticsService } from './services/analytics.service';
import { HttpErrorHandler } from './services/error-handler/http-error-handler.service';
import { MessageService } from './services/error-handler/message.service';
import { PopupService } from './services/helpers/popup.service';
import { ToastService } from './services/helpers/toast.service';
import { ReferralService } from './services/referral.service';
import { PtService } from './services/pt.service';
import { WindowRef } from './services/helpers/window-ref';
import { MemberStatesService } from './services/helpers/member-states.service';
import { CmsService } from './services/cms.service';
import { CookieService } from 'ngx-cookie-service';
import { SettingsService } from './services/settings.service';
import { ScriptService } from './services/helpers/script.service';
import { ToastSecondService } from './services/helpers/toast-second.service';
import { AwsAmplifyService } from './services/core/aws-amplify.service';
/**
 * Custom Created Modules
 * */
import { LayoutModule } from './layouts/layout.module';
import { DoNotShowOnRefreshGuard } from './guards/do-not-show-on-refresh.guard';
/**
 * Custom Created Components
 * */
import { P404Component } from '../public/other/404.component';
import { P500Component } from '../public/other/500.component';
import {LoadingScripts} from './services/helpers/loading-scripts';
import {CookiesService} from './services/helpers/cookies.service';


@NgModule({
    declarations: [
        P404Component,
        P500Component
    ],
    imports: [
        BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        LayoutModule
    ],
    providers: [
        AmplifyService,
        AwsAmplifyService,
        AwsService,
        AmplifyService,
        UserService,
        HttpService,
        HttpErrorHandler,
        CmsService,
        MessageService,
        PaymentService,
        AuthGuard,
        DoNotShowOnRefreshGuard,
        AnalyticsService,
        PopupService,
        WindowRef,
        ToastService,
        ToastSecondService,
        PtService,
        ReferralService,
        MemberStatesService,
        CookieService,
        ScriptService,
        CookiesService,
        SettingsService,
        LoadingScripts,
        { provide: LoggerService, useClass: ConsoleLoggerService },
        { provide: HTTP_INTERCEPTORS, useClass: HttpsRequestInterceptor, multi: true }
    ],
    bootstrap: [],
    exports: [P404Component, P500Component]
})
export class CoreModule {
}
