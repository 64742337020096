import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AmplifyService } from 'aws-amplify-angular';
import { Cache, Logger } from 'aws-amplify';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { AuthState } from 'aws-amplify-angular/src/providers/auth.state';
import { environment } from '../../../../environments/environment';
import { MosoUser } from '../../../models';
import {LoadingScripts} from '../helpers/loading-scripts';


@Injectable()
export class AwsAmplifyService {
    public logger = new Logger('AwsService', 'INFO');
    public loggedIn: BehaviorSubject<boolean>;

    /** Header Navigation Items List */
    private _authState: BehaviorSubject<AuthState> = new BehaviorSubject(null);
    public readonly authState$: Observable<AuthState> = this._authState.asObservable().pipe(distinctUntilChanged());

    constructor(public amplifyService: AmplifyService, private httpClient: HttpClient, private router: Router, private loadingScript: LoadingScripts) {
        this.logger.log('-- AwsAmplifyService init --');
        this.loggedIn = new BehaviorSubject<boolean>(false);
        this.authStateChange();
    }

    /////////////////////////GET GENERAL///////////////////////////////
    getAmplifyMosoAPI(path, init): Promise<any> {
        return this.amplifyService.api().get('iBlinkMosoAPI', path, init);
    }

    getAmplifyCognitoAPI(path, init): Promise<any> {
        return this.amplifyService.api().get('iBlinkCognito', path, init);
    }

    getAmplifyPricingAPI(path, init): Promise<any> {
        return this.amplifyService.api().get('iBlinkPricingApi', path, init);
    }

    getAmplifyCMSAPI(path, init): Promise<any> {
        return this.amplifyService.api().get('iBlinkCMSAPI', path, init);
    }

    getAmplifyWebBlinkCMSAPI(path, init): Promise<any> {
        return this.amplifyService.api().get('WebBlinkCMSAPI', path, init);
    }


    /////////////////////////POST GENERAL///////////////////////////////
    postAmplifyMosoAPI(path, init): Promise<any> {
        return this.amplifyService.api().post('iBlinkMosoAPI', path, init);
    }

    postAmplifyCognitoAPI(path, init): Promise<any> {
        return this.amplifyService.api().post('iBlinkCognito', path, init);
    }

    postAmplifyPricingAPI(path, init): Promise<any> {
        return this.amplifyService.api().post('iBlinkPricingApi', path, init);
    }

    postAmplifyCMSAPI(path, init): Promise<any> {
        return this.amplifyService.api().post('iBlinkCMSAPI', path, init);
    }

    postAmplifyWebBlinkCMSAPI(path, init): Promise<any> {
        return this.amplifyService.api().post('WebBlinkCMSAPI', path, init);
    }


    /////////////////////////PUT GENERAL///////////////////////////////
    putAmplifyMosoAPI(path, init): Promise<any> {
        return this.amplifyService.api().put('iBlinkMosoAPI', path, init);
    }



    /////////////////////////PROMISES///////////////////////////////

    requestNewPassword(email): Promise<any> {
        return this.amplifyService.api().post(`iBlinkCognitoRequestNewPassword`, `/requestNewPasswordForLegacyMember`, { username: email });
    }

    resetNewPassword(username: string, temporary_password: string, new_password: string): Promise<any> {
        const myInit = {
            headers: {
                'x-api-key': environment.apiConf.resetPasswordCognito
            }
        };
        const data = {
            username: username,
            temporary_password: temporary_password,
            new_password: new_password
        };
        return this.httpClient.post<any>(`${environment.apiConf.url}/cognito/resetPasswordForLegacyMember`, data, myInit).toPromise();
    }

    signInP(email, password): Promise<any> {
        return this.amplifyService.auth().signIn(email, password);
    }

    resendSignUp(email): Observable<any> {
        return from(this.amplifyService.auth().resendSignUp(email));
    }

    getCognitoUserDataP(): Promise<any> {
        return this.amplifyService.auth().currentUserInfo();
    }

    getCurrentUserAttrP(cognitoUser): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.amplifyService.auth().userAttributes(cognitoUser)
                .then((attr: any[]) => {
                    const userFormated: any = {};
                    attr.forEach((item) => {
                        const prop: any = item.getName();
                        userFormated[prop] = item.getValue();
                    });
                    resolve(userFormated);
                })
                .catch(error => reject(error));
        });
    }


    signUpP(registerData): Promise<any> {
        return this.amplifyService.auth().signUp(registerData);
    }

    getMosoUserP(id: string): Promise<MosoUser> {
        return this.amplifyService.api().get(`iBlinkMosoAPI`, `/member/${id}`, '');
    }

    getCognitoMemberById(id: string): Promise<any> {
        return this.amplifyService.api().get('iBlinkCognito', `/findByMoSoId/${id}`, '');
    }


    /////////////////////////PROMISES///////////////////////////////


    /** get authenticat state */
    isAuthenticated(): Observable<boolean> {
        return from(this.amplifyService.auth().currentAuthenticatedUser()).pipe(
            map(result => {
                this.loggedIn.next(true);
                return true;
            }),
            catchError(error => {
                this.loggedIn.next(false);
                return of(false);
            })
        );
    }

    /** signin */
    signIn(email, password): Observable<any> {
        return from(this.amplifyService.auth().signIn(email, password))
            .pipe(
                tap(() => this.loggedIn.next(true))
            );
    }

    authStateChange(): void {
        this.amplifyService.authStateChange$.subscribe(result => {
            this._authState.next(result);
        });
    }


    /** signout */
    signOut(): void {
        from(this.amplifyService.auth().signOut()).subscribe(
            result => {
                this.loggedIn.next(false);
                this.removeAllCookiesUnderDomain();
                localStorage.clear();
                this.loadingScript.deleteScript('ze-snippet');
                // this.router.navigate(['public/login']);
                window.location.href = 'public/login';
            },
            error => this.logger.error(error)
        );
    }

    getCognitoUserData(): Observable<any> {
        return from(this.amplifyService.auth().currentUserInfo()).pipe(
            map((cognitoUser: any) => {
                    if (!cognitoUser) {
                        return;
                    }
                    return {
                        givenName: cognitoUser.attributes['given_name'],
                        familyName: cognitoUser.attributes['family_name'],
                        phone: cognitoUser.attributes['custom:phone_number'],
                        email: cognitoUser.attributes['email'],
                        emailVerified: cognitoUser.attributes['email_verified'],
                        barcode: cognitoUser.attributes['custom:barcode'],
                        mosoId: cognitoUser.attributes['custom:moso_member_id'],
                        securityId: cognitoUser.attributes['custom:userSecurityId'],
                        cognitoUserNamefederatedID: cognitoUser['username'],
                        cognitoId: cognitoUser['id']
                    };
                }
            ));
    }


    /** get authenticat state */
    currentAuthenticatedUser(): Observable<any> {
        return from(this.amplifyService.auth().currentAuthenticatedUser());
    }
    /** get authenticat state */
    currentAuthenticatedUserP(): Promise<any> {
        return this.amplifyService.auth().currentAuthenticatedUser();
    }

    /*signIn(username, password): Observable<any> {
     return from(this.amplifyService.auth().signIn(username, password));
     }*/

    signUp(registerData): Observable<any> {
        this.logger.warn('User Reg new', registerData);
        return from(this.amplifyService.auth().signUp(registerData));
    }

    forgotPassword(username): Observable<any> {
        return from(this.amplifyService.auth().forgotPassword(username));
    }

    forgotPasswordSubmit(username, data: {verificationCode: string, password: string, confirmPassword: string}): Observable<any> {
        return from(this.amplifyService.auth().forgotPasswordSubmit(username, data.verificationCode, data.password));
    }

    confirmSignUp(email, code): Observable<any> {
        return from(this.amplifyService.auth().confirmSignUp(email, code));
    }


    changePassword(oldPassword, newPassword): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.amplifyService.auth().currentAuthenticatedUser()
                .then(user => {
                    return this.amplifyService.auth().changePassword(user, oldPassword as string, newPassword as string);
                })
                .then(data => {
                    resolve(data);
                    this.logger.log(data);
                })
                .catch(err => {
                    reject(err);
                    this.logger.error(err);
                });
        });
    }


    getCurrentSession(): any {
        this.amplifyService.auth().currentSession()
            .then(res => {
                Cache.getItem('federatedInfo').then(federatedInfo => {
                    this.logger.info('federatedInfo', federatedInfo);
                    const { token } = federatedInfo;
                })
                    .cache(error => this.logger.error('error', error));
                this.logger.info('CurrentSession: ', res);
            })
            .catch(err => this.logger.error(err));
    }

    getCognitoUserAttr() {
        this.amplifyService.auth().currentUserInfo().then(res => {
            this.logger.info('Cognito User Attr', res);
            if (res) {
                this.getMOSOMemberData(res.attributes['custom:moso_member_id']);
            }
        }).catch(error => this.logger.error(error));
    }

    getMOSOMemberData(memberId: string): void {
        let myInit = {
            headers: {
                'x-api-key': 'vwDZKXq3oI6RtruqbINOr9JuUO7Y5Q1h5Wa7EgBD'
            }
        };
        this.amplifyService.api().get('iBlink-MosoAPI-dev', `/member/${memberId}`, myInit)
            .then(res => this.logger.info(res))
            .catch(error => this.logger.error(error));
    }


    removeAllCookiesUnderDomain(): void {
        const cookies = document.cookie.split(';'),
            domain_name = environment.awsConfig.storageDomain;

        cookies.forEach((cooky) => {
            const equals = cooky.indexOf('='),
                name = equals > -1 ? cooky.substr(0, equals) : cooky;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=' + domain_name + ';path=/';
        });
    }

}
