import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Component, ViewEncapsulation, OnInit, OnDestroy, AfterViewChecked, AfterViewInit } from '@angular/core';
import { AnalyticsService } from './core/services/analytics.service';
import { Subscription } from 'rxjs';
import { cleanSubscriptions } from './core/helpers';
import { ToastService } from './core/services/helpers/toast.service';
import { LoadingScripts } from './core/services/helpers/loading-scripts';
import { LoggerService } from './core/services/log4ts/logger.service';


declare let $: any;

@Component({
    selector: 'body',
    templateUrl: './app.component.html',
    styleUrls: ['app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
    public template: string;
    public options: any;
    isOnline: boolean;
    public subscriptions: Array<Subscription> = [];

    constructor(public _analyticsService: AnalyticsService, public logger: LoggerService, public router: Router, public toastService: ToastService, private _loadScript: LoadingScripts) {
        this.isOnline = navigator.onLine;
    }

    onClick() {
        $('#toast').modal('hide');
    }

    ngOnInit() {
        this._loadScript.loadScriptOmniture();
        this.subscriptions.push(this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    this._analyticsService.changePageItem(event.urlAfterRedirects);
                    this._analyticsService.satelliteAction('iBlink_Top_Navigation_Angular');
                }
                if (event instanceof NavigationStart) {
                    this._analyticsService.browserRefreshState(!this.router.navigated);
                }
            }),
            this.toastService._showToast.subscribe(res => {
                if (res.visible) {
                    $('#toast').modal('show');
                }
                if (res.options) {
                    this.options = {
                        modalTitle: res.options.modalTitle,
                        modalMessage: res.options.modalMessage,
                        modalButtonTitle: res.options.modalButtonTitle,
                        modalClass: res.options.modalClass,
                        modalButtonClass: res.options.modalButtonClass,
                        isHideButton: res.options.isHideButton
                    };
                } else {
                    this.options = {
                        modalTitle: '',
                        modalMessage: '',
                        modalButtonTitle: 'OK',
                        modalClass: 'payment-pop-up',
                        modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right'
                    };
                }
            }));
        this.template = '<img class="sp" src="./assets/img/loader.svg" />';
    }

    ngAfterViewInit() {
    }

    ngAfterViewChecked() {
    }

    ngOnDestroy() {
        cleanSubscriptions(this.subscriptions);
    }


}
