import {Injectable, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from './log4ts/logger.service';
import { ToastService } from './helpers/toast.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserService } from './user.service';
import { CognitoUserModel, NewReferral, Referral } from '../../models';
import { messagesOptions } from '../const/message-options';


@Injectable()
export class ReferralService implements OnInit {
    private user: CognitoUserModel;

    private _referralList: BehaviorSubject<Referral[] | null> = new BehaviorSubject(null);
    public readonly referralList$: Observable<Referral[] | null> = this._referralList.asObservable().pipe(distinctUntilChanged());

    /**
     * ReferralService constructor
     * @param {HttpClient} httpClient
     * @param {LoggerService} logger
     * @param {ToastService} toastService
     * @param {UserService} _userService
     */
    constructor(private httpClient: HttpClient, private logger: LoggerService, public toastService: ToastService, private _userService: UserService) {
        logger.info('-PtService-');
        // this.init();
    }

    ngOnInit(): void {
        this._userService.user$.subscribe((user: CognitoUserModel) => {
                if (user && user.mosoId) {
                    this.user = user;
                    this.getReferrals(user.mosoId);
                }
            }
        );
    }
    /**
     * Init for ReferralService service
     **/
    init(): void {
        this._userService.user$.subscribe((user: CognitoUserModel) => {
                if (user && user.mosoId) {
                    this.user = user;
                    this.getReferrals(user.mosoId);
                }
            }
        );
    }

    getReferrals(mosoId: string): void {
        this.httpClient.get<Referral[]>(`${environment.apiConf.url}/referrals-api/referrals/list/${mosoId}`).pipe(
            map((referralsList: Referral[]) => {
                return referralsList.sort((a, b) => new Date(b.referralCreatedDate).getTime() - new Date(a.referralCreatedDate).getTime());
            })
        ).subscribe(
                (res: Referral[]) => {
                    this._referralList.next(res);
                },
                (error: Error) => {
                    this.logger.warn(error);
                    this.toastService.setToastVisible(messagesOptions.requestError);
                }
            );
    }

    createNewReferral(referralData: NewReferral): Observable<NewReferral> {
        if (this.user.mosoId) {
            return this.httpClient.post<NewReferral>(`${environment.apiConf.url}/referrals-api/referrals/${this.user.mosoId}`, referralData);
        }
    }

    getReferralList() {
        if (this.user && this.user.mosoId) {
            return this.httpClient.get(`${environment.apiConf.url}/referrals-api/referrals/list/${this.user.mosoId}`);
        }
    }
}
