import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import { HandleError, HttpErrorHandler } from './error-handler/http-error-handler.service';
import { LoggerService } from './log4ts/logger.service';
import { ToastService } from './helpers/toast.service';
import { messagesOptions } from '../const/message-options';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Agreement, CognitoUserModel, MemberActivity, MosoUser, PTPurchaseData, FacilitySinglePTPlan, FacilityMonthlyPTPlan, Facility } from '../../models';
import{HttpService} from '../services/core/http.service';
// import {LocalStorageService} from './helpers/local-storage.service';
// import {forEach} from '@angular/router/src/utils/collection';
// import * as SettingsData from '../const/constants';


@Injectable()
export class PtService {

    private handleError: HandleError;
    private mosoUser: MosoUser;


    private _singlePTPlan: BehaviorSubject<Plan> = new BehaviorSubject(null);
    public readonly singlePTPlan$: Observable<Plan> = this._singlePTPlan.asObservable().pipe(distinctUntilChanged());

    private _monthlyPTPlan: BehaviorSubject<Plan> = new BehaviorSubject(null);
    public readonly monthlyPTPlan$: Observable<Plan> = this._monthlyPTPlan.asObservable().pipe(distinctUntilChanged());

    private _agreementsList: BehaviorSubject<Agreement[] | null> = new BehaviorSubject(null);
    public readonly agreementslist$: Observable<Agreement[] | null> = this._agreementsList.asObservable().pipe(distinctUntilChanged());

    public _agreementsListByMemberId: BehaviorSubject<Agreement[] | null> = new BehaviorSubject(null);
    public readonly agreementsListByMemberId$: Observable<Agreement[] | null> = this._agreementsListByMemberId.asObservable().pipe(distinctUntilChanged());

    private _activitiesByCustomerId: BehaviorSubject<MemberActivity[] | null> = new BehaviorSubject(null);
    public activitiesByCustomerId$: Observable<MemberActivity[] | null> = this._activitiesByCustomerId.asObservable().pipe(distinctUntilChanged());

    // private _singleSessions: BehaviorSubject<InvoiceItem[] | null> = new BehaviorSubject(null);
    // public _singleSessions$: Observable<InvoiceItem[] | null> = this._singleSessions.asObservable().pipe(distinctUntilChanged());
    /* Plan Cache PT & SKUS */
    private _facilityMonthlyPTPlans: BehaviorSubject<FacilityMonthlyPTPlan[] | null> = new BehaviorSubject(null);
    public facilityMonthlyPTPlans: Observable<FacilityMonthlyPTPlan[] | null> = this._facilityMonthlyPTPlans.asObservable().pipe(distinctUntilChanged());

    private _facilitySinglePTPlans: BehaviorSubject<FacilitySinglePTPlan[] | null> = new BehaviorSubject(null);
    public facilitySinglePTPlans: Observable<FacilitySinglePTPlan[] | null> = this._facilitySinglePTPlans.asObservable().pipe(distinctUntilChanged());

    /**
     * PtService constructor
     * @param {HttpClient} httpClient
     * @param {LoggerService} logger
     * @param {ToastService} toastService
     * @param {HttpErrorHandler} httpErrorHandler
     * @param {UserService} _userService
     */
    constructor(private httpClient: HttpClient, private logger: LoggerService, public toastService: ToastService, public httpErrorHandler: HttpErrorHandler, public _userService: UserService, 
        private httpService: HttpService) {
        logger.info('-PtService-');
        this.handleError = httpErrorHandler.createHandleError('-PtService-');
        this.init();
    }

    /**
     * Init PT service
     **/
    init(): void {
        this._userService.user$.subscribe((user: CognitoUserModel) => {
                if (user && user.mosoId) {
                    this.getActivitiesByCustomerNew(user.mosoId);
                    this.getAgreementsByMemberId(user.mosoId);
                    this.getAgreementsRef();
                    // this.getInvoicesSearch();

                }
            }
        );

        this._userService.userDataMoso$.subscribe((data: MosoUser) => {
            if (data) {
                this.mosoUser = data;
                this.httpService.getFacilityCode(this.mosoUser.Location.Code);
                this.getFacilitySinglePTPlans();
                this.getFacilityMonthlyPTPlans();
            }
        });
    }

    getFacilityMonthlyPTPlans(): void {
        const myInit = {
            // headers: {
            //     'x-api-key': environment.cms.xApiKey
            // }
        };
        if (this.mosoUser && this.mosoUser.Location.Code) {
            this.httpClient.get<any>(`${environment.planCacheApiConf.apiUrl}/getPTPriceByFacility?BusinessUnitCode=${this.mosoUser.Location.Code}`, myInit).pipe(
                map((data: any) => {
                    const agreementsResponse = data.result.Item.AgreementPrices;
                    const agreementsResponseKeys = Object.keys(agreementsResponse);
                    const agreementsResponseArr: FacilityMonthlyPTPlan[] = [];
                    for (const prop of agreementsResponseKeys) {
                        agreementsResponseArr.push(agreementsResponse[prop]);
                    }
                    return agreementsResponseArr;
                })
            ).subscribe(plans => {
                this._facilityMonthlyPTPlans.next(plans);
            });
        }
    }

    getFacilitySinglePTPlans(): void {
        const myInit = {
            headers: {
                'x-api-key': environment.cms.xApiKey
            }
        };
        if (this.mosoUser && this.mosoUser.Location.Code) {
            this.httpClient.get<any>(`${environment.planCacheApiConf.apiUrl}/getSinglePTPriceByFacility?BusinessUnitCode=${this.mosoUser.Location.Code}`, myInit).pipe(
                map((data: any) => {
                    const agreementsResponse = data.result.Item.InvoicePrices;
                    const agreementsResponseKeys = Object.keys(agreementsResponse);
                    const agreementsResponseArr: FacilitySinglePTPlan[] = [];
                    for (const prop of agreementsResponseKeys) {
                        agreementsResponseArr.push(agreementsResponse[prop]);
                    }
                    return agreementsResponseArr;
                })
            ).subscribe(plans => {
                this._facilitySinglePTPlans.next(plans);
            });
        }
    }


    purchasePTPlan(purchaseData: PTPurchaseData): Observable<any> {
        if (purchaseData.agreementType === 'monthlyPT') {
            return this.httpClient.post(`${environment.apiConf.url}/moso-api/purchaseMonthlyPT`, purchaseData);
        } else if (purchaseData.agreementType === 'singlePT') {
            return this.httpClient.post(`${environment.apiConf.url}/moso-api/purchaseSinglePT`, purchaseData);
        } else {
            this.logger.error('Agreement type was not provided');
            this.toastService.setToastVisible(messagesOptions.requestError);
        }
    }

    /**
     * getInvoicesSearch
     */

    /*getInvoicesSearch(): void {
     const url = `${environment.apiConf.url}/moso-api/invoices/search?availableAtPOS=true&availableRecurring=false&businessUnitCode=640&categoryId=2&itemType=Bundle&memberRequired=true&qualifiedMemberOnly=false`;
     this.httpClient.get<InvoiceItem[]>(url).pipe(
     retry(1)
     ).subscribe(
     (result: InvoiceItem[]) => this._singleSessions.next(result),
     (error: Error) => {
     this.logger.error(error);
     });
     }*/

    /**
     * getAgreements(): void
     */
    getAgreementsRef(): void {
        this.httpClient.get<{facilityId: string, agreements: Agreement[]}>(`${environment.apiConf.url}/moso-api/agreements`).pipe(retry(1)).subscribe(
            (result: {facilityId: string, agreements: Agreement[]}) => this._agreementsList.next(result.agreements),
            (error: Error) => {
                this.logger.error(error);
                this.toastService.setToastVisible(messagesOptions.requestError);
            });
    }

    /**
     * getAgreementsByMemberId(): void
     * @param {string} memberId
     */
    getAgreementsByMemberId(memberId: string): void {
        this.httpClient.get(`${environment.apiConf.url}/moso-api/members/${memberId}/agreements`).pipe(retry(1)).subscribe(
            (result: any) => {
                this._agreementsListByMemberId.next(result);
            },
            (error: Error) => {
                this.logger.warn(error);
                this.toastService.setToastVisible(messagesOptions.requestError);
            });
    }

    /**
     * getActivitiesByCustomerNew(memberId: string): void
     * @param {string} memberId
     */
    getActivitiesByCustomerNew(memberId: string): void {
        this.httpClient.get<{MoreData: boolean, Results: MemberActivity[]}>(`${environment.apiConf.url}/moso-api/activities/${memberId}/activitiesbycustomer`).pipe(retry(1)).subscribe(
            (result: {MoreData: boolean, Results: MemberActivity[]}) => this._activitiesByCustomerId.next(result.Results),
            (error: Error) => {
                this.logger.error(error);
                this.toastService.setToastVisible(messagesOptions.requestError);
            });
    }

    /**
     * myPersonalTraining(): void
     */
    myPersonalTraining(): void {
        this.getMyPersonalTrainingData().then(
            (res: any) => {
                if (res.status && res.status.toLowerCase() === 'depleted') {
                    this.toastService.setToastVisible(messagesOptions.depletedPopup(res.AgreementName, res.Date, res.Qty));
                    return;
                }
                this.showPopup(res.AgreementName, res.RemainingSession, res.Date, res.Results, res.RemainingSingleSessions, res.Qty);
            }).catch(
            (error) => {
                if (error === 'NoData') {
                    this.toastService.setToastVisible(messagesOptions.ptErrorMessage);
                    return;
                }
                this.logger.info(error);
                this.toastService.setToastVisible(messagesOptions.popupNoPlan('0', '0'));
            });
    }

    /**
     * isMosoMemberHasDepletedStatus(): boolean
     * @returns {boolean}
     */
    getMosoMemberHasDepletedAgreement(): boolean {
        if (this.mosoUser && this.mosoUser.MembershipAgreements) {
            let aggr = false;

            this.mosoUser.MembershipAgreements.forEach(item => {
                if (item.MemberAgreementStatus.toLowerCase() === 'depleted') {
                    aggr = true;
                }
                if (item.MemberAgreementStatus.toLowerCase() === 'active') {
                    aggr = false;
                }
            });
            return aggr;
        } else {
            return false;
        }
    }

    /**
     * getting Member Monthly PT agreements
     */
    getMemberMonthlyPTAgreements(): Promise<MemberActivity[]> {
        return new Promise<MemberActivity[]>((resolve) => {
            this.activitiesByCustomerId$.subscribe(res => {
                if (res) {
                    const activitiesPT: MemberActivity[] = res.filter(a => a.ItemCode.toLowerCase().includes('pt'));
                    resolve(activitiesPT);
                }
            });
        });
    }

    /**
     * getting Member Single PT agreements
     */
    getMemberSinglePTAgreements(): Promise<MemberActivity[]> {
        return new Promise<MemberActivity[]>((resolve) => {
            this.activitiesByCustomerId$.subscribe(res => {
                if (res) {
                    const activitiesS: MemberActivity[] = res.filter(a => a.ItemCode.toLowerCase().includes('s'));
                    resolve(activitiesS);
                }
            });
        });
    }

    /**
     * Get member personal Training Data
     * @returns {Promise<any>}
     */
    getMyPersonalTrainingData(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.activitiesByCustomerId$.subscribe(
                (act: MemberActivity[]) => {
                    if (act) {
                        const activitiesPT: MemberActivity[] = [];
                        let activitiesS: MemberActivity[] = [];
                        act.forEach(item => {
                            if (item.ItemCode && item.ItemCode.indexOf('S') > -1 && item.UnitsAcquired > 1) {
                                activitiesS.push(item);
                            }
                            if (item.ItemCode && item.ItemCode.indexOf('PT') > -1) {
                                activitiesPT.push(item);
                            }
                        });

                        const result: any = {};
                        if (activitiesS.length > 0) {
                            activitiesS = activitiesS.reverse();
                            activitiesS.forEach(item => {
                                if (item.UnitsAvailable > 1) {
                                    result.RemainingSingleSessions = item.UnitsAvailable;
                                } else {
                                    result.RemainingSingleSessions = 0;
                                }
                            });
                        } else {
                            result.RemainingSingleSessions = 0;
                        }


                        const depletedAgreements: boolean = this.getMosoMemberHasDepletedAgreement();
                        this.logger.info('activitiesS', activitiesS);
                        this.logger.info('depletedAgreements', depletedAgreements);
                        if (depletedAgreements) {
                            activitiesS.forEach(depletedAgreement => {
                                // const EditableStartDate = new Date(depletedAgreement.EditableStartDate);
                                result.status = 'depleted';
                                result.RemainingSession = 0;
                                // result.AgreementName = depletedAgreement.AgreementName;
                                result.AgreementName = '';
                                result.Date = '';
                                // result.Date = EditableStartDate.getDate();
                            });
                        }
                        // TODO: for Monthly sesssions we need to check if one or more has active and inactive status
                        if (activitiesPT && activitiesPT.length > 0) {
                            activitiesPT.forEach(item => {
                                if (item.StatusId.toLowerCase() === 'active') {
                                    result.RemainingSession = item.UnitsAvailable;
                                    result.AgreementName = item.ItemName;
                                    result.Date = this.getBillingDate();
                                    result.Results = act;
                                    result.Qty = item.UnitsAcquired;
                                    // result.Qty = this.getQty(item.ItemCode as string);
                                }
                            });
                        }
                        resolve(result);

                    }
                },
                (error: Error) => {
                    this.logger.error(error);
                    reject('NoData');
                });
        });
    }

    getQty(itemCode: string): number {
        if (!itemCode) {
            return 0;
        }
        let qty = 0;
        this._agreementsList.getValue().map(e => e.Bundles).forEach((e: any) => {
            e.forEach((a: any) => {
                a.Items.forEach((b: any) => {
                    if (b.UPC.toLowerCase() === itemCode.toLowerCase()) {
                        qty = Number(b.Qty);
                    }
                });
            });
        });
        return qty;
    }

    /**
     * Get Billing Date
     * @returns {number | string}
     */
    getBillingDate(): number | string {
        const membershipAgreements = this.mosoUser.MembershipAgreements ? this.mosoUser.MembershipAgreements.filter(a => a.AgreementName.toLowerCase().includes('training')) : [];
        if (membershipAgreements.length === 1) {
            const EditableStartDate = new Date(membershipAgreements[0].EditableStartDate);
            return EditableStartDate.getDate();
        } else {
            return '1';
        }
    }

    /**
     * Show PopUp
     * @param {string} AgreementName
     * @param {string} RemainingSession
     * @param {string} Date
     * @param Results
     * @param {string} RemainingSingleSessions
     * @param {string} Qty
     */
    showPopup(AgreementName: string, RemainingSession: string, Date: string, Results: any, RemainingSingleSessions: any, Qty: string): void {
        if (AgreementName && (RemainingSession || RemainingSession === '0') && Date) {
            this.toastService.setToastVisible(messagesOptions.ptPopup(AgreementName, RemainingSession, Date, Results, RemainingSingleSessions, Qty));
        } else if (RemainingSingleSessions >= 0) {
            this.toastService.setToastVisible(messagesOptions.popupNoPlan(RemainingSession, RemainingSingleSessions));
        } else {
            this.toastService.setToastVisible(messagesOptions.ptErrorMessage);
        }
    }

    getSinglePTPlan(itemCode: any, facilityId: any) {
        return this.httpClient.get(environment.apiConf.url + `/web-blink.moso-api/singlePTplanPrices?itemCode=${itemCode}&facilityId=${facilityId}`).subscribe((result: Plan) => {
            const plan = new Plan();
            Object.assign(plan, result);

            this._singlePTPlan.next(plan);
        }, (err: Error) => {
            this.logger.warn('err', err);
            this.toastService.setToastVisible(messagesOptions.requestErrorReload);
        });
    }

    getMonthlyPTPlan(itemCode: any, facilityId: any) {
        return this.httpClient.get(environment.apiConf.url + `/web-blink.moso-api/monthlyPTplanPrices?agreementId=${itemCode}&facilityId=${facilityId}`).subscribe((result: Plan) => {
            const plan = new Plan();
            Object.assign(plan, result);

            this._monthlyPTPlan.next(plan);
        }, (err: Error) => {
            this.logger.warn('err', err);
            this.toastService.setToastVisible(messagesOptions.requestErrorReload);
        });
    }

}


export class Plan {
    BundleCode: string;
    PromoPrice: number;
    ItemPrice: number;
    PromotionId: string;
    AgreementPrice: number;
    agreementPaymentOptionId: number;
    annualAgreementPrice: number;
    annualAgreementPaymentOptionId: number;
    AgreementItemId: number;
    AgreementGroupId: number;
    AgreementId: number;
    AgreementName: string;
    TaxBreak: string;
    taxChargeAmount: string;
    chargedAmount: string;

    getPrice() {
        return this.PromoPrice > 0 ? this.PromoPrice : (this.ItemPrice === 0) ? this.AgreementPrice : this.ItemPrice;
    }

    getAgreementPrice() {
        return (this.AgreementPrice > 0) ? this.AgreementPrice : this.ItemPrice;
    }
}
