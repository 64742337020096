import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from './log4ts/logger.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, range } from 'rxjs';
import { CmsPage, MosoUser, Facility } from '../../models';
import { concatMap, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { messagesOptions } from '../const/message-options';
import { UserService } from './user.service';
import { ToastService } from './helpers/toast.service';
import { AwsService } from './core/aws.service';
import {LocalStorageService} from './helpers/local-storage.service';

@Injectable()
export class CmsService {
    private userMoSo: MosoUser;
    private facilities: Facility[] = [];

    private _referralsPageCms: BehaviorSubject<CmsPage> = new BehaviorSubject(null);
    public readonly referralsPageCms$: Observable<CmsPage> = this._referralsPageCms.asObservable().pipe(distinctUntilChanged());

    private _homePageCms: BehaviorSubject<CmsPage> = new BehaviorSubject(null);
    public readonly homePageCms$: Observable<CmsPage> = this._homePageCms.asObservable().pipe(distinctUntilChanged());

    private _ptPageCms: BehaviorSubject<CmsPage> = new BehaviorSubject(null);
    public readonly ptPageCms$: Observable<CmsPage> = this._ptPageCms.asObservable().pipe(distinctUntilChanged());

    private _locationDataCms: BehaviorSubject<Facility> = new BehaviorSubject(null);
    public readonly locationDataCms$: Observable<Facility> = this._locationDataCms.asObservable().pipe(distinctUntilChanged());

    private _locationsCms: BehaviorSubject<Facility[]> = new BehaviorSubject(null);
    public readonly locationsCms$: Observable<Facility[]> = this._locationsCms.asObservable().pipe(distinctUntilChanged());

    private _terms: BehaviorSubject<any> = new BehaviorSubject(null);
    public readonly terms$: Observable<any> = this._terms.asObservable().pipe(distinctUntilChanged());

    private _privacy: BehaviorSubject<any> = new BehaviorSubject(null);
    public readonly privacy$: Observable<any> = this._privacy.asObservable().pipe(distinctUntilChanged());

    /**
     * CmsService constructor
     * @param {HttpClient} httpClient
     * @param {LoggerService} logger
     * @param {UserService} _userService
     * @param {ToastService} toastService
     */
    constructor(private httpClient: HttpClient, private logger: LoggerService, private _userService: UserService, private toastService: ToastService, private _awsService: AwsService) {
        this.logger.info('-CmsService-');
        this._userService.userDataMoso$.subscribe((userMoSo: MosoUser) => {
                if (userMoSo) {
                    this.userMoSo = userMoSo;
                    this.getMemberGymLocationDataById(this.userMoSo.Location.Code);
                }
            }
        );
    }

    /**
     * cmsServiceInit CMS service
     **/
    cmsServiceInit(): void {
        this.getRewardPageContent();
        this.getHomePageContent();
        this.getPTPageContent();
        this.checkSetLocationDataAWS();
        this.getFacilities();
    }


    checkSetLocationDataAWS(): void {
        this.locationDataCms$.subscribe((data: Facility) => {
            if (data) {
                this._awsService.getDataSetData('facilityId').then().catch(() => {
                    this._awsService.setDataSetData('facilityId', data.slug).then(result => {}).catch(err => this.logger.error(err));
                });
                this._awsService.getDataSetData('facilityName').then().catch(() => {
                    this._awsService.setDataSetData('facilityName', data.name).then(result => {}).catch(err => this.logger.error(err));
                });
                this._awsService.getDataSetData('facilityAddress').then().catch(() => {
                    this._awsService.setDataSetData('facilityAddress', data.address.street + ' ' + data.address.street2).then(result => {}).catch(err => this.logger.error(err));
                });
                this._awsService.getDataSetData('facilityCity').then().catch(() => {
                    this._awsService.setDataSetData('facilityCity', data.address.city).then(result => {}).catch(err => this.logger.error(err));
                });
                this._awsService.getDataSetData('facilityState').then().catch(() => {
                    this._awsService.setDataSetData('facilityState', data.address.state).then(result => {}).catch(err => this.logger.error(err));
                });
                this._awsService.getDataSetData('facilityZip').then().catch(() => {
                    this._awsService.setDataSetData('facilityZip', data.address.zip).then(result => {}).catch(err => this.logger.error(err));
                });
            }
        });
    }


    /**
     * get Reward page content
     */
    getRewardPageContent(): void {
        this.getData('iblink-rewards-page').subscribe((result: CmsPage[]) => {
            if (result.length > 0) {
                this._referralsPageCms.next(result[0]);
            }
        }, (err: Error) => {
            this.logger.warn('err', err);
            this.toastService.setToastVisible(messagesOptions.requestErrorReload);
        });
    }

    /**
     * Get PT page Content
     */
    getPTPageContent(): void {
        this.getData('iblink-pt-page').subscribe((result: CmsPage[]) => {
            if (result.length > 0) {
                this._ptPageCms.next(result[0]);
            }
        }, (err: any) => {
            this.logger.warn('err', err);
            this.toastService.setToastVisible(messagesOptions.requestErrorReload);
        });
    }

    /**
     * Getting Home page content from cms
     */
    getHomePageContent(): void {
        this.getData('iblink-home-page').subscribe((result: CmsPage[]) => {
            if (result.length > 0) {
                this._homePageCms.next(result[0]);
            }
        }, (err: any) => {
            this.logger.warn('err', err);
            this.toastService.setToastVisible(messagesOptions.requestErrorReload);
        });
    }

    /**
     * Get Member Location Data By LocationID using slug
     * Creating Observable locationDataCms$ with public access and read only
     * @param id: number
     */
    // getMemberGymLocationDataById(id: number): void {
    //     if (!id) {
    //         return;
    //     }
    //     const url = `${environment.cms.apiUrl}${environment.cms.cmsApiStage ? '/' + environment.cms.cmsApiStage : ''}/locations?slug=${id}`;
    //     this.httpClient.get<CmsPage[]>(url).subscribe((result: CmsPage[]) => {
    //         if (result.length > 0) {
    //             this._locationDataCms.next(result[0]);
    //         }
    //     }, (err: any) => {
    //         this.logger.warn('err', err);
    //         this.toastService.setToastVisible(messagesOptions.requestErrorReload);
    //     });
    // }

    getMemberGymLocationDataById(facilityId: string): any {
        // @ts-ignore
        this.facilities = JSON.parse(localStorage.getItem('facilities'));
        if (!facilityId) {
            return;
        }
        if (this.facilities && this.facilities.length > 0) {
            this.facilities.forEach(item => {
                if (item.moso_id === facilityId) {
                    this._locationDataCms.next(item);
                }
            });
        }

        // @ts-ignore
        // console.log(JSON.parse(this.facilities));
    }

    /**
     * Get Member All Locations Data
     * Creating Observable locationsCms$ with public access and read only
     * @param options: object
     */
    // getMemberAllGymLocation(options?: { attr: string, attrValue: any }): void {
    //     const url = `${environment.cms.apiUrl}${environment.cms.cmsApiStage ? '/' + environment.cms.cmsApiStage : ''}/locations`;
    //     this.getAllData(url, () => {
    //
    //         }, (results) => {
    //             results = _.sortBy(results, [function (o) {
    //                 return o.title.rendered;
    //             }]);
    //             this._locationsCms.next(results);
    //         },
    //         (error) => {
    //             this.logger.warn('err', error);
    //             this.toastService.setToastVisible(messagesOptions.requestErrorReload);
    //         }, options
    //     );
    //}
    getFacilities(): void {
        const myInit = {
            // headers: {
            //     'x-api-key': environment.cms.xApiKey
            // }
        };
        this.httpClient.get<Facility[]>(`${environment.cms.apiUrl}/${environment.cms.cmsApiStage}/iblink/facilities?per_page=100&context=embed`, myInit).pipe(
            map((data: any[]) => {
                return data.map(item => ({
                id: item.id,
                name: item.acf.title,
                moso_id: item.acf.moso_id,
                address: item.acf.address,
                contacts: item.acf.contacts,
                geo_location: item.acf.geo_location,
                terms_id: item.acf.terms_id,
                type: item.type,
                slug: item.slug,
                zenDesk: item.acf.zendesk_selection
                }));
            })
        ).subscribe(facilities => {
            LocalStorageService.setLocaStorage('facilities', facilities);
        });
    }


    /**
     * Get All results from CMS handling the pagination results into one result
     * @param url: string
     * @param onNext: Function
     * @param onComplete: Function
     * @param onError: Function
     * @param options: object
     */
    getAllData(url, onNext, onComplete, onError, options?: { attr: string, attrValue: any }) {
        let results = [];
        let filterFn = (elem: any) => {
            return true;
        };
        if (options && options.attr && options.attrValue) {
            filterFn = (elem: any) => {
                return elem.acf[options.attr] == options.attrValue;
            };
        }

        this.httpClient.get<CmsPage[]>(url, {observe: 'response'}).pipe(
            switchMap((result) => {
                // console.log(result);
                return range(1, Number(result.headers.get('x-wp-totalpages')));
            }),
            concatMap(page =>
                this.httpClient.get<CmsPage[]>(url + '?page=' + page),
            ),
            map((elem) => {
                return elem.filter(filterFn);
            })).subscribe((result: CmsPage[]) => {
            if (result.length > 0) {
                results = results.concat(result);
            }
            onNext(result);
        }, (err: any) => {
            onError(err);
        }, () => {
            onComplete(results);
        });
    }

    /**
     * Getting Terms and Conditions based on Gym status (@open / @Presale) and location @state
     * @param slug
     * @returns {Observable<Object>}
     */
    getTermsAndConditionsData(terms_id: number): Observable<{}> {
        const myInit = {
            // headers: {
            //     'x-api-key': environment.planCacheApiConf.keyValue
            // }
        };
        if (!terms_id) {
            return;
        }
        const url = `${environment.cms.apiUrl}${environment.cms.cmsApiStage ? '/' + environment.cms.cmsApiStage : ''}/iblink/tc/${terms_id}`;
        return this.httpClient.get<CmsPage[]>(url, myInit);
    }

    /**
     * Getting CMS data
     * */
    getData(slug: any) {
        console.log('slug', slug);
        const myInit = {
            headers: {
                'x-api-key': environment.cms.xApiKey
            }
        };
        if (!slug) {
            return;
        }
        //const url = `${environment.cms.apiUrl}${environment.cms.cmsApiStage ? '/' + environment.cms.cmsApiStage : ''}/pages?slug=${slug}${environment.cms.cmsStage ? '-' + environment.cms.cmsStage : ''}`;
        const url = `${environment.cms.apiUrl}${environment.cms.cmsApiStage ? '/' + environment.cms.cmsApiStage : ''}/iblink/pages?slug=${slug}`;
        return this.httpClient.get<CmsPage[]>(url, myInit);
    }

    setTerms(data: any) {
        this._terms.next(data);
    }

    setPrivacy(data: any) {
        this._privacy.next(data);
    }


}
